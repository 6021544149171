import { FueledAccessibleScript } from './FueledAccessibleScript';
import { FueledBingScript } from './FueledBingScript';
import { FueledClientScript } from './FueledClientScript';
import { FueledGtmScript } from './FueledGtmScript';
import { FueledImpactScript } from './FueledImpactScript';
import { FueledKlaviyoScript } from './FueledKlaviyoScript';
import { FueledMntnScript } from './FueledMntnScript';
//import { FueledPinterestScript } from './FueledPinterestScript';
// import { FueledTikTokScript } from './FueledTikTokScript';
import { FueledCuVimeoTracking } from './FueledCuVimeoTracking';
import {
  FueledOneTrustScript,
  useGetOneTrustConsent,
} from './FueledOneTrustScript';
import { useAnalytics } from './hooks';

const gtmContainerId = 'GTM-WPGVRLTQ';
const bingId = '5797150';
const impactPixelId = 'A1806740-b579-4dc0-ad40-78a08792fad51';
const klaviyoId = 'H6yLPD';
const mntnPixelId = '34984';
const openTrustId = '01936375-4867-7f55-a10f-2e17bf6f641a';
//const pinterestPixelId = '2612642693140';
// const tiktokPixelId = 'C594AM16C8J46GAV3TH0';

const scripts = [
  { Component: FueledAccessibleScript, consent: ['all'] },
  { withKey: openTrustId, Component: FueledOneTrustScript, consent: ['all'] },
  { withKey: bingId, Component: FueledBingScript, consent: ['marketing'] },
  {
    withKey: impactPixelId,
    Component: FueledImpactScript,
    consent: ['marketing'],
  },
  {
    withKey: klaviyoId,
    Component: FueledKlaviyoScript,
    consent: ['marketing'],
  },
  { withKey: mntnPixelId, Component: FueledMntnScript, consent: ['marketing'] },
  { withKey: gtmContainerId, Component: FueledGtmScript, consent: ['all'] },
  //{ withKey: pinterestPixelId, Component: FueledPinterestScript },
  // { withKey: tiktokPixelId, Component: FueledTikTokScript },
  { Component: FueledCuVimeoTracking, consent: ['analytics'] },
];

export const Fueled = () => {
  const consent = useGetOneTrustConsent();
  useAnalytics(consent); // init analytics

  if (!consent) {
    return (
      <>
        <FueledClientScript consent={consent} />
        <FueledOneTrustScript withKey={openTrustId} />
      </>
    );
  }

  return (
    <>
      <FueledClientScript consent={consent} />
      {/* Uncomment the following line to enable the FueledDlDebug component */}
      {/* <FueledDlDebug /> */}
      {scripts
        .filter(
          ({ consent: componentConsent }) =>
            componentConsent.includes('all') ||
            componentConsent.every((c) => consent[c])
        )
        .map(({ withKey, Component, key }, index) => (
          <Component withKey={withKey ? withKey : ''} key={key ? key : index} />
        ))}
    </>
  );
};
