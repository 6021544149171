import { useEffect, useState } from 'react';

const DEFAULT_TIMEOUT = 10000; // 10 seconds
const POLL_INTERVAL = 100; // 100ms
const MAX_RETRY = 100;

const SCRIPTS_LOADED = {};

const waitForCallback = (
  callback,
  timeout = DEFAULT_TIMEOUT
) => {
  const startTime = Date.now();
  let retry = 0;

  return new Promise((resolve, reject) => {
    const check = () => {
      retry++;
      if (callback()) {
        resolve();
        return;
      }

      if (retry >= MAX_RETRY) {
        reject(new Error('Max retry attempts reached'));
        return;
      }

      if (Date.now() - startTime > timeout) {
        reject(new Error('Timeout waiting for callback'));
        return;
      }

      setTimeout(check, POLL_INTERVAL);
    };

    check();
  });
};

const loadScript = (
  attributes,
  target = 'head'
) => {
  const scriptId =
    attributes.id || attributes.src || attributes.innerHTML || '';
  const isScriptLoaded = SCRIPTS_LOADED[scriptId];

  if (isScriptLoaded) {
    return isScriptLoaded;
  }

  const scriptPromise = new Promise((resolve, reject) => {
    const { innerHTML, waitFor, ...attrs } = attributes;
    if (!attributes.src && !innerHTML) {
      reject(new Error('Either src or innerHTML must be provided'));
      return;
    }

    const script = document.createElement('script');

    Object.entries(attrs).forEach(([key, value]) => {
      if (value !== undefined && key !== 'code') {
        script.setAttribute(key, String(value));
      }
    });

    if (attributes.src) {
      script.src = attributes.src;
      script.onload = async () => {
        if (!waitFor) return resolve();

        try {
          await waitForCallback(waitFor);
          resolve();
        } catch (error) {
          reject(error);
        }
      };
    } else if (innerHTML) {
      script.innerHTML = innerHTML;
      (async () => {
        if (!waitFor) return resolve();

        try {
          await waitForCallback(waitFor);
          resolve();
        } catch (error) {
          reject(error);
        }
      })();
    }

    script.onerror = (e) =>
      reject(new Error(`Failed to load script: ${e.message}`));

    const targetElement = target === 'head' ? document.head : document.body;
    targetElement.appendChild(script);
  });

  SCRIPTS_LOADED[scriptId] = scriptPromise;

  return scriptPromise;
};

export const useScriptLoad = (
  attributes = {},
  target = 'body'
) => {
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState();

  useEffect(() => {
    const loadAndTrackScript = async () => {
      try {
        setStatus('loading');
        await loadScript(attributes, target);
        setStatus('ready');
      } catch (err) {
        setError(err);
        setStatus('error');
      }
    };

    loadAndTrackScript();
  }, [attributes.src, attributes.innerHTML, target]);

  return { status, error };
};

export default useScriptLoad;
