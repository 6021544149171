import Link from 'next/link';
import { Image } from '@/snippets/components/Image';

export function BorderedGrid({ cms }) {
  const getCTAclass = (style) => {
    if (style === 'link') return 'focus-link';
    if (style === 'primary') return 'no-underline button button--primary';
    if (style === 'secondary') return 'no-underline button button--secondary';
    return '';
  };

  return (
    <div
      data-comp={BorderedGrid.displayName}
      className="bordered-grid px-4 py-12 md-:px-12 md-:py-14 2xl:py-16"
      id={`${BorderedGrid.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
    >
      <style>
        {`#${BorderedGrid.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${BorderedGrid.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="mx-auto flex max-w-[1120px] flex-wrap border border-grey-1">
        <div className="w-full lg:order-last lg:w-1/2">
          {cms?.image && cms?.over_grid && (
            <div className="-m-2.5 flex h-[calc(100%+20px)] w-[calc(100%+20px)] items-center justify-center">
              <Image
                className="w-full object-cover"
                width="1160"
                height="1240"
                loading="lazy"
                sizes="40vw"
                src={cms.image.src}
                alt={cms.image.alt}
              />
            </div>
          )}
          {cms?.image && !cms?.over_grid && (
            <div className="relative h-full w-full">
              <Image
                className="w-full object-cover"
                width="1120"
                height="1200"
                loading="lazy"
                sizes="40vw"
                src={cms.image.src}
                alt={cms.imageAlt}
              />
            </div>
          )}
        </div>
        <div className="w-full border-t border-grey-1 lg:order-first lg:flex lg:w-1/2 lg:flex-col lg:border-t-0 lg:border-r">
          {cms?.heading && (
            <div className="border-b border-grey-1 px-7 py-6 md-:px-[120px] md-:py-8 lg:px-12 lg:py-6 xl:py-8">
              <h3 className="text-header mb-0 font-light">{cms.heading}</h3>
            </div>
          )}
          <div className="px-7 py-6 md-:px-[120px] md-:py-8 lg:flex-grow lg:px-12 lg:py-6 xl:py-8">
            {cms?.body && (
              <div
                className="bordered-grid-body w-full"
                dangerouslySetInnerHTML={{ __html: cms.body }}
              />
            )}
            {cms?.cta_link?.text && cms?.cta_link?.url && (
              <Link href={cms.cta_link.url}>
                <a
                  className={`${getCTAclass(
                    cms.cta_style
                  )} mt-6 inline-block cursor-pointer text-[14px] leading-[22px] 2xl:mt-8`}
                >
                  {cms.cta_link.text}
                </a>
              </Link>
            )}
          </div>
          {cms?.icons?.length > 0 && (
            <div className="bordered-grid-icons border-t border-grey-1">
              {cms.icons.map((icon, iconIdx) => (
                <div
                  className="bordered-grid-icon border-r border-grey-1 py-6 last:border-r-0"
                  key={`icon-${iconIdx}`}
                >
                  {icon?.image && (
                    <img
                      className="mx-auto mb-3 block aspect-square h-14 w-14 md-:mb-[18px] md-:h-[66px] md-:w-[66px] xl:mb-2 xl:h-[76px] xl:w-[76px]"
                      loading="lazy"
                      src={icon.image.src}
                      alt={icon.iconAlt || icon.title}
                      width="160"
                      height="160"
                    />
                  )}
                  {icon?.title && (
                    <p className="text-xsmall mb-0 font-gothamBold">
                      {icon.title}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BorderedGrid.displayName = 'BorderedGrid';

BorderedGrid.Schema = {
  category: 'Modules',
  label: BorderedGrid.displayName,
  key: 'BorderedGrid',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'body',
      label: 'Body',
      description: 'This holds paragraph text and ordered/unordered list',
      component: 'html',
    },
    {
      name: 'cta_link',
      label: 'CTA Link',
      component: 'link',
    },
    {
      name: 'cta_style',
      label: 'CTA Link Style',
      component: 'select',
      options: [
        { label: 'Text Link', value: 'link' },
        { label: 'Button Primary', value: 'primary' },
        { label: 'Button Secondary', value: 'secondary' },
      ],
      defaultValue: 'link',
    },
    {
      name: 'icons',
      label: 'Icons',
      component: 'group-list',
      fields: [
        {
          name: 'title',
          label: 'Title',
          component: 'text',
        },
        {
          name: 'image',
          label: 'Image Icon',
          component: 'image',
          description:
            'PNG/SVG format image, Recommended Icon Size: 160px x 160px (square dimension)',
        },
        {
          name: 'iconAlt',
          label: 'Image alt text',
          component: 'text',
        },
      ],
    },
    {
      name: 'image',
      label: 'Image',
      component: 'image',
      description:
        'Recommended Over Grid Image Size: 1160px x 1240px, without: 1120px x 1200px',
    },
    {
      name: 'imageAlt',
      label: 'Image alt text',
      component: 'text',
    },
    {
      name: 'over_grid',
      label: 'Over Grid Image?',
      component: 'toggle',
      toggleLabels: { true: 'Yes', false: 'No' },
      defaultValue: false,
    },
  ],
};
