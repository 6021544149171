import { useEffect, useMemo, useState } from 'react';
import { fetchProductsFromHandles } from '@/utils/product';
import {
  useCartTotals,
  useSettings,
  useCartAddItems,
  useCartRemoveItems,
  useCartUpdateItem,
  useCountry,
  useCollectionByHandle,
} from '@backpackjs/storefront';
import Cookies from 'js-cookie';
import { getSizedImageUrl } from '@/utils/Images';
import { IconRemove } from '..';

export function GWPHandler({gwpItems, cartLines, cartView = false}) {
  const siteSettings = useSettings();
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode == 'US';
  const gwpScenarios  = siteSettings?.newFreeProducts?.gwpScenarios;
  const { subtotal } = useCartTotals();
  const { fetchCollectionByHandle } = useCollectionByHandle({ handle: null });
  const { cartAddItems } = useCartAddItems();
  const { cartRemoveItems } = useCartRemoveItems();
  const { cartUpdateItem } = useCartUpdateItem();
  const optedOutCampaigns = Cookies.get('OptedOutCampaigns')?.split(',') || [];

  let activeGWPs = gwpScenarios?.filter(scenario => {
    return scenario.enabled == true;
  })
  let collectionBasedGwps = activeGWPs?.filter((gwp) => gwp._template == 'buyFromCollectionGetY');

  const [gwpProducts, setGwpProducts] = useState([]);
  const [gwpCollections, setGwpCollections] = useState([]);
  const [reviewedScenarios, setReviewedScenarios] = useState(0);
  const [giftsToAdd, setGiftsToAdd] = useState([]);
  const [giftsToRemove, setGiftsToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optInButtons, setOptInButtons] = useState([]);

  const validateProductInCart = (handle, enableSubQualify) => {
    const qualifies = cartLines?.some((line) => {
      let lineQualifies = line.variant.product.handle === handle;
      if (lineQualifies && enableSubQualify) {
        lineQualifies = line?.sellingPlanAllocation?.sellingPlan || false;
      }

      return lineQualifies;
    })

    // console.log('validate product in cart?: ', qualifies, 'enableSubQualify?: ', enableSubQualify);

    setReviewedScenarios((currentReviewedScenarios) => currentReviewedScenarios + 1);
    return qualifies || false;
  }

  const validateProductInCollection = (handle) => {
    const collection  = gwpCollections.find((collection) => collection.handle === handle);
    const cartProducts = cartLines?.map((line) => line.variant.product.handle);
    const qualifies = cartProducts?.length > 0 && cartProducts?.some((product) => {
      return collection?.products?.includes(product);
    })

    setReviewedScenarios((currentReviewedScenarios) => currentReviewedScenarios + 1);
    return qualifies || false;
  }

  const validateCartTotal = (requiredTotal) => {
    // console.log('Validate cart total is larger?:', parseFloat(subtotal) >= requiredTotal, 'subtotal:', parseFloat(subtotal), 'requiredTotal:', requiredTotal)
    setReviewedScenarios((currentReviewedScenarios) => currentReviewedScenarios + 1);
    return parseFloat(subtotal) >= requiredTotal;
  }

  const validateSubscriptionsInCart = (gwpAllSubsQualify = false) => {
    const method = gwpAllSubsQualify ? 'every' : 'some';

    const qualifies = cartLines?.length > 0 && cartLines?.[method]((line) => {
      return line?.sellingPlanAllocation?.sellingPlan;
    })

    // console.log('validate subscriptions in cart?: ', qualifies, 'All products must be subscriptions?: ', gwpAllSubsQualify);

    setReviewedScenarios((currentReviewedScenarios) => currentReviewedScenarios + 1);
    return qualifies;
  }

  const validateBundleIncart = (bundleLength, productHandles, campaign) => {
    let qualifies = false;
    const bundleItem = cartLines?.find((line) => {
      return line.isBundle;
    })

    const bundleItemCampaign = bundleItem?.attributes.find((attribute) => attribute.key === '_campaign')?.value;

    if(bundleItemCampaign && bundleItemCampaign !== campaign) {
      setReviewedScenarios((currentReviewedScenarios) => currentReviewedScenarios + 1);
      return false;
    }

    if(bundleItem) {
      qualifies = bundleItem.bundleItems.length >= bundleLength;
    }

    if(productHandles && productHandles.length > 0) {
      qualifies = bundleItem?.bundleItems?.some((line) => {
        return productHandles.includes(line.variant.product.handle)
      })
    }

    // console.log('validate bundle in cart?: ', qualifies, 'bundle length: ', bundleLength, 'items in bundle: ', bundleItem?.bundleItems?.length, 'Qualifier product:', productHandle);

    setReviewedScenarios((currentReviewedScenarios) => currentReviewedScenarios + 1);
    return qualifies;
  }

  const checkFreeGiftAdded = (gwpProduct, campaign) => {
    let handles = []

    if(Array.isArray(gwpProduct)) {
      handles = gwpProduct.map((product) => product.handle);
    } else {
      handles = [gwpProduct?.handle];
    }

    const item = gwpItems?.find((item) => {
      let validProduct = false;
      // Validate the handle exists in the cart
      const validHandle = handles.some((handle) => item.variant.product.handle === handle);
      // Validate the item matches with the campaign
      if (validHandle) {
        const validCampaign = item.attributes?.some((attribute) => {
          return attribute.key === '_campaign' && attribute.value === campaign;
        });

        if (validCampaign) {
          validProduct = true;
        }
      }
      return validProduct;
    });

    return item;
  }

  const checkCampaignOptedOut = (campaign) => {
    return optedOutCampaigns.some((optedOutCampaign) => {
      return optedOutCampaign === campaign;
    });
  }

  const optOutCampaignProduct = ({cartLine, removeFromCart = false, eventData}) => {
    let campaign;

    if(cartLine) {
      campaign = cartLine.attributes.find((attribute) => {
        return attribute.key === '_campaign';
      })?.value;
    }

    if(eventData) {
      campaign = eventData.campaign;
    }

    const optedOutCampaignObject = gwpScenarios.find((scenario) => { return scenario.campaign == campaign });
    const newOptedOutCampaigns = [...optedOutCampaigns.filter((loopCampaign) => campaign !== loopCampaign), campaign];

    if(cartLine && removeFromCart) {
      setIsLoading(true);

      cartRemoveItems({lineIds: [cartLine.id]}).then(() => {
        setIsLoading(false);
        Cookies.set('OptedOutCampaigns', newOptedOutCampaigns);
        setOptInButtons((oldCampaigns) => [
          ...oldCampaigns,
          optedOutCampaignObject,
        ]);
      });

      return;
    }

    Cookies.set('OptedOutCampaigns', newOptedOutCampaigns);
    setOptInButtons((oldCampaigns) => [
      ...oldCampaigns.filter((loopCampaign) => optedOutCampaignObject.campaign !== loopCampaign.campaign),
      optedOutCampaignObject,
    ]);
  }

  const optInCampaignProduct = (campaign) => {
    const filteredOptedOutCampaigns = optedOutCampaigns.filter((optedOutCampaign) => {
      return optedOutCampaign !== campaign.campaign;
    });
    const isMultipleProductCampaign = Array.isArray(campaign.gwpProduct);

    if(isMultipleProductCampaign) {
      document.dispatchEvent(new CustomEvent('bwp:openGiftSelector', {detail: campaign}));
    } else {
      const product = gwpProducts.find((gwpProduct) => gwpProduct.handle == campaign.gwpProduct.handle);
      const itemsToAdd = [{
        merchandiseId: product.variants[0].id,
        quantity: 1,
        attributes: [
          {
            key: "_gwp",
            value: "true",
          },
          {
            key: '_campaign',
            value: campaign.campaign,
          }
        ]
      }]
      setIsLoading(true);
      cartAddItems(itemsToAdd).then(() => {
        setIsLoading(false);
      });
    }

    if(filteredOptedOutCampaigns.length > 0) {
      Cookies.set('OptedOutCampaigns', filteredOptedOutCampaigns);
    } else {
      Cookies.remove('OptedOutCampaigns');
    }

    setOptInButtons((oldCampaigns) =>
      oldCampaigns.filter(
        (optedOutCampaign) => optedOutCampaign.campaign !== campaign.campaign
      )
    );
  }

  useEffect(() => {
    const activeGWPProductsHandles = activeGWPs?.map((gwp) => {
      if(Array.isArray(gwp.gwpProduct)) {
        return gwp.gwpProduct.map((product) => product.handle)
      } else {
        return gwp.gwpProduct.handle
      }
    }).filter((value) => !!value && value !== '' );

    if(activeGWPProductsHandles?.length) {
      fetchProductsFromHandles(activeGWPProductsHandles.flat()).then(products => {
        const filteredProducts = products.filter((product) => product);
        setGwpProducts(filteredProducts)
      });
    }

    const gwpCollectionValidations = activeGWPs?.filter((gwp) => gwp._template == 'buyFromCollectionGetY') || [];
    const collectionsObjects = gwpCollectionValidations?.map((gwp) => fetchCollectionByHandle({handle: gwp.gwpCollectionQualify}));

    Promise.all(collectionsObjects).then((collections) => {
      setGwpCollections(() => {
        return collections.map((collection) => ({
          handle: collection.handle,
          products: collection.products.map((product) => product.handle),
        }));
      })
    })

    document.addEventListener('gwp:optOutFreeGift', (evt) => {
      optOutCampaignProduct({
        eventData: evt.detail
      });
    });
  }, [])

  useEffect(() => {
    // Removes all gifts when the store is not in US or the cart is empty
    if(cartLines == 0 || (!isUs && !cartView)) {
      const lineIds = gwpItems?.map((lineItem) => lineItem.id);
      if(lineIds.length) {
        cartRemoveItems({lineIds});
      }
      setOptInButtons([]);
      return;
    }

    if(gwpProducts.length > 0 &&
       !isLoading &&
       gwpCollections.length == collectionBasedGwps.length &&
       isUs) {
      const newOptinButtons = [];
      activeGWPs = activeGWPs?.map((gwp) => {
        let newProduct;

        if(Array.isArray(gwp.gwpProduct)) {
          newProduct = gwp.gwpProduct.filter((product) => {
            return gwpProducts.some((gwpProduct) => gwpProduct.handle == product.handle);
          })
        } else {
          newProduct = gwpProducts.find((gwpProduct) => {
            return gwpProduct?.handle == gwp?.gwpProduct?.handle;
          })
        }

        return {
          ...gwp,
          gwpProduct: newProduct
        }
      })

      const invalidGWP = gwpItems?.filter((gwp) => {
        let isInvalid = false;
        const campaign = gwp.attributes.find((attribute) => {
          return attribute.key === '_campaign';
        })

        if(!campaign) {
          isInvalid = true;
        } else {
          isInvalid = !activeGWPs?.some((gwpScenario) => {
            return gwpScenario.campaign === campaign.value;
          });
        }

        return isInvalid;
      })


      if(invalidGWP?.length > 0) {
        setGiftsToRemove((prevGiftsToRemove) => [
          ...prevGiftsToRemove,
          ...invalidGWP,
        ]);
      }

      activeGWPs?.forEach((gwpScenario) => {
        let qualifies = false;
        const freeGiftAdded = checkFreeGiftAdded(gwpScenario.gwpProduct, gwpScenario.campaign);
        const campaignOptedOut = checkCampaignOptedOut(gwpScenario.campaign);
        switch(gwpScenario._template) {
          case 'buyXGetY':
            qualifies = validateProductInCart(gwpScenario.gwpProductQualify?.handle, gwpScenario.enableSubQualify);
            break;
          case 'buyFromCollectionGetY':
            qualifies = validateProductInCollection(gwpScenario.gwpCollectionQualify);
            break;
          case 'spendXGetY':
            qualifies = validateCartTotal(gwpScenario.gwpCartMinimum);
            break;
          case 'spendXandChooseY':
            qualifies = validateCartTotal(gwpScenario.gwpCartMinimum);
            break;
          case 'buySubscriptionGetY':
            qualifies = validateSubscriptionsInCart(gwpScenario.gwpAllSubsQualify);
            break;
          case 'buyBundleGetY':
            qualifies = validateBundleIncart(
              gwpScenario.gwpBundleCount,
              [gwpScenario.gwpProductQualify?.handle, gwpScenario.gwpProductQualifySecondary?.handle],
              gwpScenario.campaign);
            break;
          case 'freeSamples':
            qualifies = validateCartTotal(gwpScenario.samplesCartMinimum);
            break;
        }

        if(qualifies == true) {
          if(campaignOptedOut) {
            newOptinButtons.push(gwpScenario);
          } else {
            if(!freeGiftAdded && !cartView) {
              setGiftsToAdd((prevGiftsToAdd) => [...prevGiftsToAdd, gwpScenario]);
            }
          }
        } else {
          if(freeGiftAdded && !cartView) {
            setGiftsToRemove((prevGiftsToRemove) => [...prevGiftsToRemove, freeGiftAdded]);
          }
        }
      })
      setOptInButtons(newOptinButtons);
    }

    return () => {
      setReviewedScenarios(0);
      setGiftsToAdd([]);
      setGiftsToRemove([]);
    }
  }, [cartLines, gwpCollections.length, gwpProducts.length, isLoading, isUs, gwpScenarios])

  useEffect(() => {
    if(reviewedScenarios < activeGWPs?.length) return;
    if(giftsToAdd.length > 0) {
      let multipleGiftScenarios = [];
      let itemsToAdd = [];
      // Switch map for forEach
      giftsToAdd.forEach((scenario) => {
        // if scenario has multiple gift push it to a different variable
        if(Array.isArray(scenario.gwpProduct)) {
          multipleGiftScenarios.push(scenario);
        } else {
          console.log(scenario);
          itemsToAdd.push({
            merchandiseId: scenario.gwpProduct.variants[0].id,
            quantity: 1,
            attributes: [
              {
                key: "_gwp",
                value: "true",
              },
              {
                key: '_campaign',
                value: scenario.campaign,
              },
              {
                key: '_sc',
                value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
              }
            ]
          });
        }
      })

      cartAddItems(itemsToAdd).then(() => {
        if(giftsToRemove.length > 0) {
          const linesToRemove = giftsToRemove.map((item) => item.id);
          cartRemoveItems({lineIds: linesToRemove});
        }
      });

      if(multipleGiftScenarios.length > 0) {
        document.dispatchEvent(new CustomEvent('bwp:openGiftSelector', {detail: multipleGiftScenarios[0]}));
      }
    } else {
      if(giftsToRemove.length > 0) {
        const linesToRemove = giftsToRemove.map((item) => item.id);
        cartRemoveItems({lineIds: linesToRemove});
      }
    }
  }, [giftsToAdd.length, giftsToRemove.length, reviewedScenarios])

  useEffect(() => {
    const moreThanOneGwp = gwpItems?.find((gwp) => {
      return gwp.quantity > 1;
    })

    if(moreThanOneGwp) {
      cartUpdateItem({
        lineId: moreThanOneGwp.id,
        quantity: 1,
        attributes: moreThanOneGwp.attributes
      })
    }
  }, [gwpItems, cartLines])
  return (
    <>
      {!cartView && gwpItems?.map((gwpLine) => (
        <div key={gwpLine.id} className="gwp-line-item-container relative flex items-center justify-between border-b border-grey-4 py-5 px-4">
          <div className="w-20 shrink-0">
            <img
              src={getSizedImageUrl(gwpLine?.variant?.image?.src, '100x100@2x')}
              alt={gwpLine?.variant?.product?.title}
              width="80"
              height="80"
            />
          </div>
          <button
            className="z-1 absolute right-4 top-5 inline-block cursor-pointer"
            onClick={() => {
              optOutCampaignProduct({
                cartLine: gwpLine,
                removeFromCart: true,
              });
            }}
            type="button"
          >
            <IconRemove />
          </button>
          <div className="relative flex-grow pl-3">
            <div className="my-0 inline-block pr-8 font-gothamBold text-xs no-underline">
              {gwpLine?.variant?.product?.title}
            </div>
            <p className="mt-0 mb-0 text-xs lg:mt-1">one-time purchase</p>
            <div className="mt-6 flex items-center justify-between">
              <span />
              <div className="text-right font-gothamBold text-sm">FREE</div>
            </div>
          </div>
        </div>
      ))}
      {isUs && optInButtons.map((campaign) => (
        <div key={campaign.campaign} className="line-item-add-gwp relative flex items-center justify-between border-b border-grey-4 py-5 px-4">
          <button
            type="button"
            className="border-b-2 font-gothamBold text-sm"
            onClick={() => {
              optInCampaignProduct(campaign);
            }}
          >
            {campaign?.gwpAddBack}
          </button>
        </div>
      ))}
    </>
  );
}