
import Link from 'next/link';
import Slider from 'react-slick';

function Section({ cms }) {
  const itemCount = cms?.items?.length || 0;
  const textColor = cms?.textColor || 'black';
  const sectionCta = cms?.cta;
  if (itemCount <= 0) {
    return null;
  }

  const slideSettings = {
    infinite: false,
    variableWidth: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipeToSlide: true,
    swipe: true,
    adaptiveHeight: false,
    useTransform: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2.1,
          dots: true,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1.8,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          dots: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1.1,
          dots: true,
        },
      }
    ],
  };

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      key={cms.cmsId}
      className={`section-howto-build-bundle section-howto-build-bundle--${cms?.layout} pt-[35px] pb-14 lg:pb:16 text-${cms?.contentAlign} text-${textColor} ${cms.bgColor}`}
      data-comp={Section.displayName}
    >
      <div className={`${cms?.layout == 'container' ? 'mx-auto max-w-[1188px]' : ''}`}>
        {/* Section Header */}
        <div className='px-5'>
          {cms?.sectionSubTitle && (
            <div className="sub-regular">
              {cms?.sectionSubTitle}
            </div>
          )}
          {cms?.sectionTitle && (
            <h2 className="text-h4-m md:text-h4 my-1 p-0">
              {cms?.sectionTitle}
            </h2>
          )}
          {cms?.sectionDescription && (
            <div className="text-large">
              {cms?.sectionDescription}
            </div>
          )}

          {sectionCta?.link?.text && sectionCta?.link?.url && (
            <Link href={sectionCta.link.url}>
              <a
                className={`${sectionCta.style} no-underline mt-6 lg:mt-4`}
                target={sectionCta.link.newTab ? '_blank' : null}
              >
                {sectionCta.link.text}
              </a>
            </Link>
          )}
        </div>

        {/* Section Content */}
        {cms?.items?.length > 0 && (
          <div className={`mt-9 lg:mt-11 ${cms?.layout == 'container' ? 'md:px-5' : ''}`}>
            <CustomSlider
              settings={slideSettings}
              className={`flex flex-nowrap md:flex-wrap md:justify-center ${cms?.layout == 'container' ? 'gap-6' : ''}`}
            >
              {cms?.items?.map((cta, index) => {
                return (
                  <ItemHtml
                    key={index}
                    item={cta}
                    index={index}
                    textColor={textColor}
                    showStepNumber={cms?.showStepNumber}
                    itemContentAlign={cms?.itemContentAlign}
                    isLastItem={cms?.items?.length - 1 === index}
                    layout={cms?.layout}
                  />
                );
              })}
            </CustomSlider>
          </div>
        )}
      </div>
    </div>
  );
}

function CustomSlider({ className, settings, children }, props) {
  return (
    <>
      <div className="md:hidden">
        <Slider {...settings} {...props}>
          {children}
        </Slider>
      </div>
      <div className={`max-md:hidden ${className}`}>{children}</div>
    </>
  );
}

function ItemHtml({ item, index, textColor, showStepNumber, itemContentAlign, isLastItem, layout }) {
  if (!item?.image?.src && !item?.title && !item?.description) return null;

  return (
    <div
      className={`flex-1 md:p-0
        text-${textColor} text-${itemContentAlign} border-${textColor} h-full md:h-auto flex flex-col
        ${layout == 'container' ? `max-w-[368px] pl-4` : ``}`}
      data-slide
      data-index={index}
  >
    <div className={`relative flex flex-col h-full ${layout == 'container' ? `border` : `border-y border-l`} ${(isLastItem && layout != 'container') ? `after:content-[""] after:absolute after:h-full after:border-l after:border-${textColor} after:right-0` : ''}`}>
      {/* Item header */}
      <div className={`flex-1 flex items-center`}>
        {showStepNumber && (
          <span className='text-[50px] leading-[62px] font-tiempos font-light flex items-center justify-center m-0 min-w-[80px]'>{index + 1}</span>
        )}
        <div className={`flex flex-col justify-center h-full py-4 px-5 lg:py-6 ${showStepNumber ? `border-l border-${textColor}` : ''}`}>
          {item?.title && (
            <p className="m-0 mb-1 sub-regular">
              {item?.title}
            </p>
          )}
          {item?.description && (
            <div className="text-small">
              {item?.description}
            </div>
          )}
        </div>
      </div>

      {/* Item Image */}
      {item?.image?.src && (
        <div
          className={`relative w-full h-0 pt-[115.5%] border-t border-${textColor}`}
        >
          <img
            src={item.image.src}
            className="absolute inset-0 w-full h-full p-4 object-contain"
            alt={item.title}
            width="736"
            height="850"
          />
        </div>
      )}
    </div>
  </div>
  );
}

Section.displayName = 'Bundle Builder How to';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'bundle-builder-how-to',
  fields: [
    {
      component: 'select',
      name: 'layout',
      label: 'Layout',
      options: [
        { label: 'Full Width', value: 'fullWidth' },
        { label: 'Container', value: 'container' }
      ],
      defaultValue: 'container',
    },
    {
      component: 'select',
      name: 'contentAlign',
      label: 'Heading Alignment',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' },
      ],
      defaultValue: 'center',
    },
    {
      component: 'text',
      label: 'Section SubTitle',
      name: 'sectionSubTitle',
      defaultValue: 'Bundle builder',
    },
    {
      component: 'text',
      label: 'Section Title',
      name: 'sectionTitle',
      defaultValue: 'Customize your routine'
    },
    {
      component: 'textarea',
      label: 'Section Description',
      name: 'sectionDescription',
      defaultValue: 'Create your perfect routine and save at the same time.'
    },
    {
      label: 'CTA',
      name: 'cta',
      component: 'group',
      fields: [
        {
          label: 'CTA URL',
          name: 'link',
          component: 'link',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Cta Style',
          options: [
            { label: 'Primary', value: 'button button--primary inline-block' },
            {
              label: 'Secondary',
              value: 'button button--secondary inline-block',
            },
            {
              label: 'Tertiary',
              value: 'button button--tertiary inline-block',
            },
            { label: 'Focus Link', value: 'focus-link' },
            { label: 'Focus Link White', value: 'focus-link-white' },
            {
              label: 'checkout',
              value: 'button button--checkout inline-block',
            },
          ],
          defaultValue: 'button button--primary inline-block',
        },
      ],
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color',
      options: [
        { label: 'Light', value: 'white' },
        { label: 'Dark', value: 'black' },
      ],
      defaultValue: 'black',
    },
    {
      component: 'select',
      name: 'bgColor',
      label: 'Background Color',
      description: 'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' },
        { label: 'Scalp Blue', value: 'bg-scalp-blue' },
      ],
      defaultValue: 'bg-grey-9',
    },
    {
      component: 'toggle',
      name: 'showStepNumber',
      label: 'Show Step Number in Item?',
      toggleLabels: {
        true: 'Show',
        false: 'Hide'
      },
      defaultValue: true,
    },
    {
      component: 'select',
      name: 'itemContentAlign',
      label: 'Item Content Alignment',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' },
      ],
      defaultValue: 'left',
    },
    {
      component: 'group-list',
      name: 'items',
      label: 'Items',
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
          description: 'Recommended Image Size: 736px x 850px',
        },
        {
          label: 'Title',
          name: 'title',
          component: 'text',
        },
        {
          label: 'Title',
          name: 'title',
          component: 'text',
        },
        {
          label: 'Description',
          name: 'description',
          component: 'textarea',
        },
      ],
      validate: {
        maxItems: 3,
      }
    }
  ],
};

export const HowToBuildBundle = Section;
