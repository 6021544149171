import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useDataLayerActions } from '@hooks';
import { useKlaviyoApi } from '../../../hooks/useKlaviyoApi';


const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function EmailSignup() {
  const { sendSubscribeEvent } = useDataLayerActions();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const settings = useSettings();
  const { subscribeToList } = useKlaviyoApi();

  const emailSettings = settings?.footer?.email;
  const listId = emailSettings?.listId || 'HQmQ53';

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (isLoading) return;

      if (!email) {
        setMessage('Email is required');
        setTimeout(() => {
          setMessage('');
        }, 3000);
        return;
      }

      const isEmailValid = validateEmail(email);
      if (!isEmailValid) {
        setMessage('Email is invalid');
        setTimeout(() => {
          setMessage('');
        }, 3000);
        return;
      }

      setIsLoading(true);

      await subscribeToList({
        email,
        listId,
        source: 'Footer subscription form',
        callback: (response) => {
          if (response === 'success') {
            setIsLoading(false);
            setMessage(emailSettings?.successMessage || 'Email submitted!');
            sendSubscribeEvent({ email });
            setEmail('');
            setTimeout(() => {
              setMessage('');
            }, 4000);
          } else {
            setIsLoading(false);
            setEmail('');
            setMessage('Please try again later');
          }
        }
      })
    },
    [email, isLoading, message]
  );

  return (
    <div>
      <p className="mb-2 font-gothamBold text-white">
        {emailSettings?.title || 'sign up for our newsletter'}
      </p>
      <div className="mb-4 flex items-center justify-between bg-grey-3 p-1 pr-2">
        <div
          className="text-input relative flex w-[20.625rem] flex-col self-start"
          style={{ marginBottom: message ? '1.75rem' : '0' }}
        >
          <input
            name="email-address"
            id="email-address"
            type="email"
            value={email}
            placeholder="Email Address"
            required
            style={{ outline: 'none' }}
            className="w-full cursor-pointer border-0 border-black bg-transparent py-2 px-4 text-white placeholder-grey-7 transition-all placeholder-shown:border-grey-7 placeholder-shown:text-white hover:border-black hover:text-white hover:placeholder-white focus:rounded-none focus:text-white focus:placeholder-white focus:outline-ada-outline"
            onChange={(e) => setEmail(e.target.value)}
            aria-required="true"
            aria-label="Email Address"
          />
          {message && (
            <p className="text-xsmall absolute top-[calc(100%+0.5rem)] w-full px-4 text-white">
              {message}
            </p>
          )}
        </div>
        <button
          type="button"
          aria-label="submit"
          className="button--white button--small lowercase"
          style={
            message
              ? {
                  marginBottom: 'auto',
                  marginTop: '5px',
                }
              : {}
          }
          onClick={handleSubmit}
        >
          {emailSettings?.buttonText || 'Submit'}
        </button>
      </div>
    </div>
  );
}

export default EmailSignup;
