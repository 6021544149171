import { forwardRef } from 'react';
import Head from 'next/head';

const IMAGE_SIZES = [16, 32, 48, 64, 96, 128, 256, 384];
const DEVICE_SIZES = [640, 768, 960, 1280, 1536, 2048, 2560, 3072, 3840];
const ALL_SIZES = [...IMAGE_SIZES, ...DEVICE_SIZES].sort((a, b) => a - b);
const DEFAULT_QUALITY = 75; // 1 - 100

function getWidths({ config, sizes, width }) {
  const { deviceSizes = [], allSizes = [] } = { ...config };
  if (sizes) {
    // Find all the "vw" percent sizes used in the sizes prop
    const percentSizes = (sizes.match(/(1?\d?\d)vw/g) || []).map((size) =>
      parseInt(size, 10)
    );
    if (percentSizes.length) {
      const smallestRatio = Math.min(...percentSizes) * 0.01;
      return {
        widths: allSizes.filter((s) => s >= deviceSizes[0] * smallestRatio),
        kind: 'w',
      };
    }
    return { widths: allSizes, kind: 'w' };
  }

  // For "fill" images with no "sizes" prop, return all device sizes
  if (typeof width !== 'number') {
    return { widths: deviceSizes, kind: 'w' };
  }

  // For images with set widths, find first size at least twice the width
  const widths = [
    ...new Set(
      [width, width * 2].map(
        (w) => allSizes.find((p) => p >= w) || allSizes[allSizes.length - 1]
      )
    ),
  ];
  return { widths, kind: 'x' };
}

function generateImgAttrs({ config, loader, quality, sizes, src, width }) {
  const { widths, kind } = getWidths({ config, sizes, width });
  const last = widths.length - 1;

  return {
    sizes: !sizes && kind === 'w' ? '100vw' : sizes,
    srcSet: widths
      .map(
        (w, i) =>
          `${loader({ src, quality, width: w })} ${
            kind === 'w' ? w : i + 1
          }${kind}`
      )
      .join(', '),
    src: loader({ quality, src, width: widths[last] }),
  };
}

function getInt(value) {
  if (typeof value === 'number' || typeof value === 'undefined') {
    return value;
  }
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return NaN;
}

// Shopify CDN specific url param mutations
// For other CDNs, create another custom loader
function shopifyCdnLoader({ src, quality, width }) {
  if (!src) return '';
  const params = new URLSearchParams(src.split('?')[1] || '');
  params.set('width', width);
  params.set(
    'quality',
    quality < 1 || quality > 100 ? DEFAULT_QUALITY : quality
  );
  return `${src.split('?')[0]}?${params}`;
}

export const ImageElement = forwardRef(
  (
    {
      alt,
      className,
      fill,
      height,
      loading,
      priority,
      quality = DEFAULT_QUALITY,
      sizes,
      src,
      width,
      ...props
    },
    ref
  ) => {
    const config = { allSizes: ALL_SIZES, deviceSizes: DEVICE_SIZES };
    const qualityInt = getInt(quality);
    const widthInt = getInt(width);
    const heightInt = getInt(height);

    const imgAttributes = generateImgAttrs({
      config,
      loader: shopifyCdnLoader,
      quality: qualityInt,
      sizes,
      src,
      width: widthInt,
    });

    return (
      <>
        {priority && (
          <Head>
            <link
              rel="preload"
              as="image"
              href={imgAttributes.src}
              {...(fill
                ? {
                    imageSrcSet: imgAttributes.srcSet,
                    imageSizes: imgAttributes.sizes,
                  }
                : null)}
            />
          </Head>
        )}

        <img
          alt={alt}
          className={`${
            fill
              ? 'absolute inset-0 m-auto box-border block h-0 max-h-full min-h-full w-0 min-w-full max-w-full border-none object-cover p-0'
              : ''
          } ${className}`}
          decoding="async"
          loading={priority ? 'eager' : loading}
          ref={ref}
          src={imgAttributes.src}
          {...(fill && { sizes: imgAttributes.sizes, srcSet: imgAttributes.srcSet })}
          width={widthInt}
          height={heightInt}
          {...props}
        />
      </>
    );
  }
);

ImageElement.displayName = 'ImageElement';
