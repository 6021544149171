import Link from 'next/link';
import { Image } from '@/snippets/components/Image';
import { newLineToBr } from '@/utils/Helper';

export function Iconography({ cms }) {
  const getCTAclass = (style) => {
    if (style === 'link') return 'focus-link';
    if (style === 'white-link') return 'focus-link-white';
    return '';
  };

  return (
    <div
      id={`${Iconography.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={Iconography.displayName}
      className={`${cms.bgColor}`}
    >
      <style>
        {`#${Iconography.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Iconography.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div
        className={`mx-auto max-w-[1440px] px-4 pt-[38px] lg:px-6 lg:pt-[64px] lg:pb-[26px] ${cms.fgColor}`}
      >
        {cms?.intro && (
          <p className="text-regular mb-1.5 text-center font-gothamBold md:mx-auto md:max-w-[430px]">
            {cms.intro}
          </p>
        )}
        <h3 className="text-header mb-3 text-center md:mx-auto md:mb-4 md:max-w-[430px]">
          {cms.heading}
        </h3>
        {cms?.content && (
          <p
            className="mb-9 text-center font-light md:mx-auto md:max-w-[430px] md:text-[18px] md:leading-[26px]"
            dangerouslySetInnerHTML={{ __html: newLineToBr(cms.content) }}
          />
        )}
        {cms?.icons?.length > 0 && (
          <div
            className={`-mx-4 flex flex-wrap justify-evenly text-center sm:justify-center lg:mx-0 ${cms.fgColor}`}
          >
            {cms.icons.map((icon, iconIdx) => (
              <div
                className="iconography-item mb-[38px] max-w-[176px] px-2 md:px-3 lg:max-w-[228px]"
                key={`iconography-item-${iconIdx}`}
              >
                {icon?.image && (
                  <div className="relative mx-auto mb-[18px] block aspect-square h-[85px] w-[85px] md:h-[100px] md:w-[100px]">
                    <Image
                      src={icon.image.src}
                      alt={icon.imageAlt}
                      width="100"
                      height="100"
                      fill
                      sizes="33vw"
                      loading="lazy"
                    />
                  </div>
                )}
                {icon?.title && (
                  <p className="mb-1.5 font-gothamBold text-[16px] leading-[22px] md:mb-1 md:text-[18px] md:leading-[26px]">
                    {icon.title}
                  </p>
                )}
                {icon?.description && (
                  <p
                    className="mb-3 text-[14px] font-light leading-[22px] md:text-[16px] md:leading-[24px]"
                    dangerouslySetInnerHTML={{
                      __html: newLineToBr(icon.description),
                    }}
                  />
                )}
                {icon?.cta_link?.text && icon.cta_link?.url && (
                  <Link href={icon.cta_link.url}>
                    <a
                      className={`${getCTAclass(
                        icon.cta_style
                      )} cursor-pointer text-[14px] leading-[22px]`}
                    >
                      {icon.cta_link.text}
                    </a>
                  </Link>
                )}
              </div>
            ))}
          </div>
        )}
        {cms?.cta_main_link?.text && cms?.cta_main_link?.url && (
          <div className="flex justify-center pt-[16px] pb-[38px]">
            <Link href={cms.cta_main_link.url}>
              <a
                className={`${cms.cta_main_style} mt-6 cursor-pointer text-[14px] leading-[22px] no-underline 2xl:mt-8`}
              >
                {cms.cta_main_link.text}
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

Iconography.displayName = 'Iconography';

Iconography.Schema = {
  category: 'Product',
  label: Iconography.displayName,
  key: 'iconography',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'intro',
      label: 'Intro Title',
      component: 'text',
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'content',
      label: 'Content',
      component: 'textarea',
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'select',
      description:
        'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' },
        { label: 'Scalp Blue', value: 'bg-scalp-blue' },
      ],
      defaultValue: 'bg-white',
    },
    {
      name: 'fgColor',
      label: 'Foreground Color',
      component: 'select',
      description:
        'For section foreground color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'text-white' },
        { label: 'Dark', value: 'text-grey-1' },
      ],
      defaultValue: 'text-grey-1',
    },
    {
      name: 'icons',
      label: 'Icons',
      component: 'group-list',
      fields: [
        {
          name: 'image',
          label: 'Icon Image',
          component: 'image',
          description:
            'PNG or SVG Format Icon Image, Recommended Image Size: 200px x 200px',
        },
        {
          name: 'imageAlt',
          label: 'Image alt text',
          component: 'text',
        },
        {
          name: 'title',
          label: 'Title',
          component: 'text',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'textarea',
        },
        {
          name: 'cta_link',
          label: 'CTA Link',
          component: 'link',
          description: 'Optional CTA Link',
        },
        {
          name: 'cta_style',
          label: 'CTA Link Style',
          component: 'select',
          options: [
            { label: 'Focus Link', value: 'link' },
            { label: 'Focus Link White', value: 'white-link' },
          ],
          defaultValue: 'link',
        },
      ],
    },
    {
      name: 'cta_main_link',
      label: 'CTA Link',
      component: 'link',
    },
    {
      component: 'select',
      name: 'cta_main_style',
      label: 'CTA Style',
      options: [
        { label: 'Primary', value: 'button button--primary inline-block' },
        { label: 'Secondary', value: 'button button--secondary inline-block' },
        { label: 'Tertiary', value: 'button button--tertiary inline-block' },
      ],
      defaultValue: 'button button--primary inline-block',
    },
  ],
};
