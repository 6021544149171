import { useEffect, useMemo } from 'react';
import { useScriptLoad } from './useScriptLoad';

export const CONSENT_API =
  'https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js';
export const SHOPIFY_CART_DOMAIN =
  process.env.SHOPIFY_CART_DOMAIN || 'shop.briogeohair.com';

export const useCustomerPrivacy = ({
  checkoutRootDomain = SHOPIFY_CART_DOMAIN,
  storefrontAccessToken,
  onVisitorConsentCollected,
}) => {
  const config = useMemo(
    () => ({
      checkoutRootDomain,
      storefrontAccessToken,
      storefrontRootDomain: parseStoreDomain(checkoutRootDomain),
    }),
    [checkoutRootDomain, storefrontAccessToken]
  );

  useScriptLoad(
    {
      id: 'customer-privacy-api',
      src: CONSENT_API,
    },
    'head'
  );

  useEffect(() => {
    const consentCollectedHandler = (event) => {
      if (onVisitorConsentCollected) {
        onVisitorConsentCollected(event.detail);
      }
    };

    document.addEventListener(
      'visitorConsentCollected',
      consentCollectedHandler
    );

    return () => {
      document.removeEventListener(
        'visitorConsentCollected',
        consentCollectedHandler
      );
    };
  }, [onVisitorConsentCollected]);

  useEffect(() => {
    let customCustomerPrivacy = null;
    let customShopify = window.Shopify || undefined;

    Object.defineProperty(window, 'Shopify', {
      configurable: true,
      get() {
        return customShopify;
      },
      set(value) {
        if (
          typeof value === 'object' &&
          value !== null
        ) {
          customShopify = value;

          Object.defineProperty(window.Shopify, 'customerPrivacy', {
            configurable: true,
            get() {
              return customCustomerPrivacy;
            },
            set(value) {
              if (
                typeof value === 'object' &&
                value !== null &&
                'setTrackingConsent' in value
              ) {
                const customerPrivacy = value;

                customCustomerPrivacy = {
                  ...customerPrivacy,
                  setTrackingConsent: overrideCustomerPrivacySetTrackingConsent(
                    { customerPrivacy, config }
                  ),
                };

                customShopify = {
                  ...customShopify,
                  customerPrivacy: customCustomerPrivacy,
                };
              }
            },
          });
        }
      },
    });
  }, []);

  return {
    customerPrivacy: getCustomerPrivacy(),
  };
};

const overrideCustomerPrivacySetTrackingConsent = ({ customerPrivacy, config }) => {
  const original = customerPrivacy.setTrackingConsent;

  return (consent, callback) => original({
    ...config,
    headlessStorefront: true,
    ...consent,
  },
  callback
)};


function parseStoreDomain(checkoutDomain) {
  if (typeof window === 'undefined') return;

  const host = window.document.location.host;
  const checkoutDomainParts = checkoutDomain.split('.').reverse();
  const currentDomainParts = host.split('.').reverse();
  const sameDomainParts = [];
  checkoutDomainParts.forEach((part, index) => {
    if (part === currentDomainParts[index]) {
      sameDomainParts.push(part);
    }
  });

  return sameDomainParts.reverse().join('.');
}

export const getCustomerPrivacy = () => {
  try {
    return window.Shopify && window.Shopify.customerPrivacy
      ? (window.Shopify?.customerPrivacy)
      : null;
  } catch (e) {
    return null;
  }
}