import { useEffect } from 'react';
import { useAnalytics, useScriptLoad, TRACKING_EVENTS } from './hooks';

// Function to hash email using SHA-1
const hashEmail = async (email) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hash = await crypto.subtle.digest('SHA-1', data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

export function FueledImpactScript({ withKey: impactPixelId }) {
  const { register, subscribe } = useAnalytics();

  const { status } = useScriptLoad({
    id: 'impact-script',
    src: `https://utt.impactcdn.com/${impactPixelId}.js`,
    waitFor: () => !!window?.ire,
  }, 'head');

  const ready = register('impact');
  useEffect(() => {
    if (status !== 'ready') return;

    const userDataListener = async (event) => {
      console.log('User properties:', event.detail.user_properties);
      const userProps = event.detail.user_properties;
      if (userProps?.customer_email) {

        const hashedEmail = await hashEmail(userProps.customer_email);
        window.ire('identify', {
          customerId: userProps?.customer_id,
          customerEmail: hashedEmail,
        });

        console.log('Firing identify event', {
          customerId: userProps?.customer_id,
          customerEmail: hashedEmail,
        });
      } else {
        window.ire('identify', { customerId: '', customerEmail: '' });
        console.log('Firing identify event with empty data');
      }
    };

    subscribe(TRACKING_EVENTS.PAGE_VIEW, userDataListener);

    ready();
  }, [status]);

  return null;
}