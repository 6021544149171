import { useEffect } from 'react';

export const TRACKING_EVENTS = {
  LOGIN: 'dl_login',
  SIGN_UP: 'dl_sign_up',
  VIEW_CART: 'dl_view_cart',
  VIEW_ITEM: 'dl_view_item',
  SUBSCRIBE: 'dl_subscribe',
  USER_DATA: 'dl_user_data',
  ADD_TO_CART: 'dl_add_to_cart',
  SELECT_ITEM: 'dl_select_item',
  PAGE_VIEW: 'dl_route_update',
  VIEW_ITEM_LIST: 'dl_view_item_list',
  REMOVE_FROM_CART: 'dl_remove_from_cart',
  VIEW_SEARCH_RESULTS: 'dl_view_search_results',
};

const clients = {};
const eventCache = new Map();
const subscribers = new Map();
const eventListeners = new Set();

export const useAnalytics = () => {
  const areClientsReady = () =>
    Object.values(clients).every(Boolean) &&
    Object.keys(clients).length > 0;

  const publishEvent = (eventName, payload) => {
    (subscribers.get(eventName) || new Set()).forEach((callback) => {
      try {
        callback(payload);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const subscribe = (eventName, callback) => {
    if (!subscribers.has(eventName)) {
      subscribers.set(eventName, new Set());
    }
    subscribers.get(eventName).add(callback);
    return () => {
      const callbacks = subscribers.get(eventName);
      if (callbacks) {
        callbacks.delete(callback);
        if (callbacks.size === 0) {
          subscribers.delete(eventName);
        }
      }
    };
  };

  const publish = (eventName, payload) => {
    if (!areClientsReady()) {
      eventCache.set(eventName, payload);
      return;
    }
    publishEvent(eventName, payload);
  };

  const register = (client) => {
    if (typeof clients[client] === 'undefined') {
      clients[client] = false;
    }

    return () => {
      clients[client] = true;
      if (areClientsReady() && eventCache.size > 0) {
        eventCache.forEach((payload, eventName) => {
          publishEvent(eventName, payload);
        });
        eventCache.clear();
      }
    };
  };

  useEffect(() => {
    const events = Object.values(TRACKING_EVENTS);
    const abortController = new AbortController();

    const handleEvent = (event) => {
      publish(event.type, event);
    };

    events.forEach((eventName) => {
      if (eventListeners.has(eventName)) return;
      eventListeners.add(eventName);

      window.addEventListener(eventName, handleEvent, { signal: abortController.signal });
    });

    return () => {
      abortController.abort();
      eventListeners.clear();
    };
  }, []);

  return { clients, register, subscribe, publish };
};