import { Markdown } from './Markdown';
import { Styleguide } from './Styleguide';
import { Banner } from './Banner';
import { Contentwithsidebar } from './Contentwithsidebar';
import { Ingredients } from './Ingredients';
import { ProductSizes } from './ProductSizes';
import { Iconography } from './Iconography';
import { Accordion } from './Accordion';
import { Spacer } from './Spacer';
import { CollectionBanner } from './CollectionBanner';
import { FourUpCta } from './FourUpCta';
import { StudyStatistics } from './StudyStatistics';
import { ProductSpotlight } from './ProductSpotlight';
import { ThreeUp } from './ThreeUp';
import { ImageWithText } from './ImageWithText/ImageWithText';
import { Hero } from './Hero';
import { CategoryCarousel } from './CategoryCarousel';
import { CarouselHotspots } from './CarouselHotspots';
import { BorderedGrid } from './BorderedGrid';
import { GorgiasContactForm } from './GorgiasContactForm';
import { ProductUpsells } from './ProductUpsells';
import { ProductRecommendations } from './ProductRecommendations';
import { ParallaxStory } from './ParallaxStory';
import { Quiz } from './Quiz';
import { Collection } from './Collection';
import { FullWidthText } from './FullWidthText';
import { StoreLocator } from './StoreLocator';
import { BeamCommunityImpact } from './BeamCommunityImpact';
import { CollectionGrid } from './CollectionGrid';
import { CustomCursor } from './CustomCursor';
import { BundleBuilder } from './BundleBuilder';
import { QuizSection } from './QuizSection';
import { Testimonial } from './Testimonial';
import { BeforeAfterSlider } from './BeforeAfterSlider';
import { VideoWise } from './VideoWise';
import { BuyWithPrimePLP } from './BuyWithPrimePLP';
import { CtaSlider } from './CtaSlider';
import { HowToBuildBundle } from './HowToBuildBundle';
import { Quotes } from './Quotes';
import { TableBuilder } from './TableBuilder';

export default [
  Markdown,
  Styleguide,
  Banner,
  Contentwithsidebar,
  Iconography,
  Accordion,
  Ingredients,
  ProductSizes,
  Spacer,
  ThreeUp,
  FourUpCta,
  StudyStatistics,
  ProductSpotlight,
  CategoryCarousel,
  ImageWithText,
  Hero,
  CarouselHotspots,
  BorderedGrid,
  GorgiasContactForm,
  ProductUpsells,
  CollectionBanner,
  ProductRecommendations,
  ParallaxStory,
  Quiz,
  Collection,
  FullWidthText,
  StoreLocator,
  BeamCommunityImpact,
  CollectionGrid,
  CustomCursor,
  BundleBuilder,
  QuizSection,
  Testimonial,
  BeforeAfterSlider,
  VideoWise,
  BuyWithPrimePLP,
  CtaSlider,
  HowToBuildBundle,
  Quotes,
  TableBuilder,
];
