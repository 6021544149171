import { useCallback, useEffect } from 'react';
import { useCountry, useCountries } from '@backpackjs/storefront';
import Cookies from 'js-cookie';

function CountrySelector() {
  const countries = useCountries();
  const { country, updateCountry } = useCountry();
  const haveCountries = countries?.length > 0;

  const updateSelectedCountry = useCallback(
    async (selected) => {
      const selectedCountry = countries?.find(
        (_country) => _country.isoCode === selected
      );

      await updateCountry({
        country: selectedCountry,
      });

      Cookies.set('country-selector_customer-country', selectedCountry.isoCode);
    },
    [countries?.length]
  );

  const handleCountrySelect = (selected) => {
    updateSelectedCountry(selected);
  };

  useEffect(() => {
    if (countries?.length && country) {
      const selectedCountry = countries?.find(
        (_country) => _country.isoCode === country?.isoCode
      );

      if (!selectedCountry) updateSelectedCountry('US');
    }
  }, [countries?.length, country]);

  return (
    haveCountries && (
      <div className="w-full text-center">
        <div className="select-wrap-4 relative bottom-[10px] my-[12px] inline-block md:absolute md:right-[24px] lg:right-[100px] md:my-[0px]">
          <select
            className="h-[28px] w-[auto] cursor-pointer border border-grey-5 bg-[#696969] p-0 py-0 pl-[10px] pr-[40px] text-center text-[12px] text-[13px] text-[#F5F5F5] placeholder-black outline-0 placeholder-shown:border-grey-5 hover:border-grey-5"
            name="country-select"
            id="country-select"
            value={country?.isoCode || 'US'}
            onChange={(e) => handleCountrySelect(e.target.value)}
            aria-label="Select a country"
          >
            {countries
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((option, optionIndex) => (
                <option key={`option-${optionIndex}`} value={option.isoCode}>
                  {`${option.name} ${option?.locale?.currency}`}
                </option>
              ))}
          </select>
        </div>
      </div>
    )
  );
}

export default CountrySelector;
