import { useSettings } from '@backpackjs/storefront';
import Link from 'next/link';

function FooterMenu(cms, ...props) {
  const settings = useSettings();

  return (
    settings?.footer?.footerMenu1?.length > 0 && (
      <div className="footer-menu-1 flex w-full max-w-[432px] justify-between pt-4 md:pt-0">
        <ul className="mt-0 pl-0">
          {settings?.footer?.footerMenu1.map((linkItem, linkIndex) => {
            return (
              linkItem?.link?.url &&
              linkItem?.link?.text && (
                <li className="mt-0 mb-2" key={`footerMenu1${linkIndex}`}>
                  {linkItem.link.url.indexOf('http') > -1 ? (
                    <a
                      href={linkItem.link.url}
                      target={linkItem.link.newTab === true ? '_blank' : '_self'}
                      className="text-small lg:text-regular lowercase text-white no-underline hover:text-grey-7 hover:underline"
                      rel="noreferrer"
                    >
                      {linkItem.link.text}
                    </a>
                  ) : (
                    <Link href={linkItem.link.url}>
                      <a
                        title={linkItem.link.text}
                        target={
                          linkItem.link.newTab === true ? '_blank' : '_self'
                        }
                        className="text-small lg:text-regular lowercase text-white no-underline hover:text-grey-7 hover:underline"
                      >
                        {linkItem.link.text}
                      </a>
                    </Link>
                  )}
                </li>
              )
            );
          })}
        </ul>
        {settings?.footer?.footerMenu2?.length > 0 && (
        <ul className="mt-0 pl-0 sm:pr-10 md:pr-0">
          {settings?.footer?.footerMenu2.map((linkItem, linkIndex) => {
            return (
              linkItem?.link?.url &&
              linkItem?.link?.text && (
                <li className="mt-0 mb-2" key={`footerMenu2${linkIndex}`}>
                  {linkItem.link.url.indexOf('http') > -1 ? (
                    <a
                      href={linkItem.link.url}
                      target={linkItem.link.newTab === true ? '_blank' : '_self'}
                      className="text-small lg:text-regular lowercase text-white no-underline hover:text-grey-7 hover:underline"
                      rel="noreferrer"
                    >
                      {linkItem.link.text}
                    </a>
                  ) : (
                    linkItem.link.url && linkItem.link.url ==='/pages/do-not-sell-my-information' ? (
                      <button id="ot-sdk-btn" className="ot-sdk-show-settings">{linkItem.link.text}</button>
                    ) : (
                      <Link href={linkItem.link.url}>
                        <a
                          title={linkItem.link.text}
                          target={
                            linkItem.link.newTab === true ? '_blank' : '_self'
                          }
                          className="text-small lg:text-regular lowercase text-white no-underline hover:text-grey-7 hover:underline"
                        >
                          {linkItem.link.text}
                        </a>
                      </Link>
                    )
                  )}
                </li>
              )
            );
          })}
        </ul>
        )}
      </div>
    )
  );
}

export default FooterMenu;
