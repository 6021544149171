import { useEffect, useRef, useState } from "react";
import { QuizContext } from "./QuizContext";
import { QuizQuestions } from "./QuizQuestions";
import { QuizMailGate } from "./QuizMailGate";
import { QuizResult } from "./QuizResults";

export function QuizSection ({cms}) {
  const sectionRef = useRef();
  const [quizData, setQuizData] = useState({
    quizStep: 'steps',
    currentQuestion: 0,
    answers: [],
    filter: '',
    hasError: false,
    isFiltered: false,
    filteredQuestions: []
  });

  const filterQuestionData = {
    message1: cms?.filterSettings?.filterQuestionMessage1,
    message2: cms?.filterSettings?.filterQuestionMessage2,
    isMultipleChoice: cms?.filterSettings?.isMultipleChoice,
    showOptionsImages: cms?.filterSettings?.showOptionsImages,
    optionsColumns: cms?.filterSettings?.optionsColumns,
    options: cms?.filterSettings?.filterOptions,
    helpTitle: cms?.filterSettings?.filterHelpTitle,
    helpContent: cms?.filterSettings?.filterHelpContent,
    isFilterQuestion: true,
  }

  const hasFilterQuestion = !!filterQuestionData.message1 && filterQuestionData.options.length > 0;

  const questionList = hasFilterQuestion ? [filterQuestionData, ...cms.questions]: cms.questions;

  const quizSettings = {
    title: cms.title,
    description: cms.description,
    questions: questionList.map((question, index) => { return {...question, index}}),
    mailGateSettings: cms.mailGate,
    resultTitle: cms.resultTitle,
    resultDescription: cms.resultDescription,
    baseRoutineTitle: cms.baseRoutineTitle,
    baseRoutineDescription: cms.baseRoutineDescription,
    specializedRoutineTitle: cms.specializedRoutineTitle,
    specializedRoutineDescription: cms.specializedRoutineDescription,
    addAllTop: cms.addAllTop,
    addAllLabel: cms.addAllLabel,
    enableSaveResults: cms.enableSaveResults,
    saveResultsLabel: cms.saveResultsLabel,
    resultBannerTitle: cms.resultBannerTitle,
    resultBannerBody: cms.resultBannerBody,
    desktopBannerLightText: cms.desktopBannerLightText,
    resultBannerLink: cms.resultBannerLink,
    bannerImageDesktop: cms.bannerImageDesktop,
    bannerImageMobile: cms.bannerImageMobile,
    textColor: cms.textColor || 'text-white',
    bgColor: cms.bgColor || 'bg-primary-2',
  };

  const updateQuizData = (newData) => {
    setQuizData({
      ...quizData,
      ...newData,
    });
  }

  const getResultsProducts = () => {
    const products = [];

    quizData?.answers?.forEach((answer) => {
      if(!answer) {return};
      const question = quizSettings.questions.find((question) => {
        return question.index === answer.questionIndex;
      })

      // Multiple answers question
      if(Array.isArray(answer.answer)) {
        let resultProductList = []
        const selectedOptions = question.options.filter((option, index) => {
          return answer.answer.includes(String(index));
        })

        selectedOptions.forEach((option) => {
          option.productList?.forEach((productObject) => {
            if(!productObject.product) {return;}

            resultProductList.push({
              product: productObject.product,
              routine: productObject.routineType,
              resultMessage: productObject.resultMessage,
            })
          })
        })

        products = [...products, ...resultProductList];
      } else {
        // Single answer question
        if(answer.answer.indexOf('-') > -1) {
          // Question with follow up question
          const mainOption = question.options[Number.parseInt(answer.answer.split('-')[0])];
          const followUpOption = question.options[Number.parseInt(answer.answer.split('-')[0])].followUpOptions[Number.parseInt(answer.answer.split('-')[1])];
          const mainOptionProducts = mainOption?.productList?.map((productObject) => {
            if(!!productObject.product) {
              return {
                product: productObject.product,
                routine: productObject.routineType,
                resultMessage: productObject.resultMessage,
              }
            }
          })
          const followUpOptionProducts = followUpOption?.productList?.map((productObject) => {
            if(!!productObject.product) {
              return {
                product: productObject.product,
                routine: productObject.routineType,
                resultMessage: productObject.resultMessage,
              }
            }
          })
          if(mainOptionProducts) {
            products = [...products, ...mainOptionProducts]
          }
          if(followUpOptionProducts) {
            products = [...products, ...followUpOptionProducts]
          }
        } else {
          // Question without follow up question
          const option = question.options.find((option, index) => {
            return String(index) === answer.answer;
          })

          const optionProducts = option?.productList?.map((productObject) => {
            if(!!productObject.product) {
              return {
                product: productObject.product,
                routine: productObject.routineType,
                resultMessage: productObject.resultMessage,
              }
            }
          })

          if(option && optionProducts) {
            products = [...products, ...optionProducts]
          }
        }
      }
    })

    return products;
  }

  const parseQuizResultUrl = () => {
    const results = quizData.answers.filter((answer) => answer);
    const currentSearchParams = new URLSearchParams('step=results');
    const parsedResults = results.map((result) => {
      if(result) {
        return `${result.questionIndex}:${Array.isArray(result.answer) ? result.answer.join(',') : result.answer}`
      }
    })
    parsedResults.forEach((result) => {
      currentSearchParams.append('responses', result)
    })

    return `${document.location.host}${document.location.pathname}?${currentSearchParams.toString()}`
  }

  const buildSummaryResults = () => {
    const resultsArray = [];
    quizData.answers.forEach((answerItem) => {
      if(!answerItem) {return;}
      const currentQuestion = quizSettings.questions.find((question) => question.index === answerItem.questionIndex);

      if(currentQuestion?.isFilterQuestion == true) {
        return;
      }

      let answer = null;

      if (answerItem.answer.indexOf('-') > -1) {
        const mainQuestionAnswer = currentQuestion?.options[answerItem.answer.split('-')[0]].label;
        const followUpQuestionAnswer = currentQuestion?.options[answerItem.answer.split('-')[0]].followUpOptions[answerItem.answer.split('-')[1]].label;
        answer = mainQuestionAnswer + ' ' + followUpQuestionAnswer;
      } else {
        answer = Array.isArray(answerItem.answer) ?
        answerItem.answer.map((index) => currentQuestion?.options?.[index]?.label).join(', ') : currentQuestion?.options[answerItem.answer]?.label;
      }

      resultsArray.push({
        label: currentQuestion?.summaryLabel,
        answer,
      })
    })

    return resultsArray;
  }

  useEffect(() => {
    if (quizData.filter == '') {
      updateQuizData({
        isFiltered: false,
        filteredQuestions: quizSettings.questions,
      });
    } else {
      const filteredQuestions = quizSettings.questions.filter((question) => {
        return question.isFilterQuestion || quizData.filter.includes(question.category);
      });
      updateQuizData({
        isFiltered: true,
        filteredQuestions: filteredQuestions,
      });
    }

    if(typeof window != 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const step = urlParams.get('step');
      const answers = urlParams.getAll('responses').map((answer) => {
        const answerPieces = answer.split(':');
        return {
          questionIndex: Number.parseInt(answerPieces[0]),
          answer: answerPieces[1].indexOf(',') > -1 ? answerPieces[1].split(',') : answerPieces[1],
        }
      });
      if(typeof step != 'undefined' && answers.length > 0){
        updateQuizData({
          quizStep: step,
          answers,
          loadedFromUrl: true,
        })
      }
    }
  }, [quizData.filter])

  useEffect(() => {
    window.scrollTo({top: 0});
  }, [quizData.currentQuestion, quizData.quizStep])

  return (
    <QuizContext.Provider value={{quizData, updateQuizData, quizSettings, getResultsProducts, parseQuizResultUrl, buildSummaryResults}}>
      <section ref={sectionRef} className="quizContainer">
        {quizData.quizStep == 'steps' && (
          <QuizQuestions />
        )}
        {quizData.quizStep == 'mailGate' && (
          <QuizMailGate />
        )}
        {quizData.quizStep == 'results' && (
          <>
            <QuizResult />
          </>
        )}
      </section>
    </QuizContext.Provider>
  )
}

QuizSection.displayName = 'Quiz Section';

QuizSection.Schema = {
  category: 'Modules',
  label: QuizSection.displayName,
  key: 'quizSection',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'text',
      name: 'title',
      label: 'Title',
      defaultValue: "Let's find your perfect match!",
    },
    {
      component: 'markdown',
      name: 'description',
      label: 'Description',
      defaultValue:
        'Chat with our virtual hair expert to find the perfect products for you!',
    },
    {
      component: 'select',
      name: 'bgColor',
      label: 'Background Color',
      description: 'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Scalp Blue', value: 'bg-scalp-blue' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-primary-2',
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color',
      options: [
        { label: 'Light', value: 'text-white' },
        { label: 'Dark', value: 'text-black' },
      ],
      defaultValue: 'text-white',
    },
    {
      component: 'group',
      name: 'filterSettings',
      label: 'Filter Question Settings',
      fields: [
        {
          component: 'textarea',
          name: 'filterQuestionMessage1',
          label: 'Filter Question Message 1',
          description: 'Message to be shown as the intro for the filter question',
        },
        {
          component: 'textarea',
          name: 'filterQuestionMessage2',
          label: 'Filter Question Message 2',
          description:
            'Message to be shown as the follow up for the filter question',
        },
        {
          component: 'toggle',
          name: 'isMultipleChoice',
          label: 'Is Multiple Choice?',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: false,
        },
        {
          component: 'toggle',
          name: 'showOptionsImages',
          label: 'Show Options Images?',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: false,
        },
        {
          component: 'select',
          name: 'optionsColumns',
          label: 'Options Columns',
          options: [
            {
              label: '1 Column',
              value: 'grid-cols-1',
            },
            {
              label: '2 Columns',
              value: 'grid-cols-2',
            },
          ],
        },
        {
          component: 'text',
          name: 'filterHelpTitle',
          label: 'Help Modal Title',
        },
        {
          component: 'group-list',
          name: 'filterHelpContent',
          label: 'Help Modal content',
          fields: [
            {
              component: 'image',
              name: 'image',
              label: 'Image',
              description: 'Optional, recomended image size: 180px x 180px',
            },
            {
              component: 'markdown',
              name: 'body',
              label: 'Body content',
              description: 'Required, content to be displayed in the modal',
            },
          ],
        },
        {
          component: 'group-list',
          name: 'filterOptions',
          label: 'Filter Options',
          itemProps: {
            label: '{{item.label}}',
          },
          fields: [
            {
              component: 'text',
              name: 'label',
              label: 'Option Label',
              description: 'Label the customer will see on the option button',
            },
            {
              component: 'text',
              name: 'value',
              label: 'Option Value',
              description:
                "Value used to filter the questions, this value should match exactly with the reference attribute added to the questions below, if empty this will force all the questions set below to be shown with no filters, also all other selected options will be disabled so this doesn't mix up with other filters",
            },
            {
              component: 'image',
              name: 'image',
              label: 'Option Image',
              description:
                'Image displayed next to the option, only used if the filter question is set as an image question',
            },
          ],
        },
      ]
    },
    {
      component: 'group-list',
      name: 'questions',
      label: 'Questions',
      itemProps: {
        label: '{{item.summaryLabel}}',
      },
      fields: [
        {
          component: 'text',
          name: 'category',
          label: 'Category',
          description:
            'Value to be used when filtering the customer flow by the first question',
        },
        {
          component: 'textarea',
          name: 'message1',
          label: 'Message 1',
          description: 'Text to be shown before the question',
        },
        {
          component: 'textarea',
          name: 'message2',
          label: 'Message 2',
          description: 'Text to be shown before the question',
        },
        {
          component: 'text',
          name: 'summaryLabel',
          label: 'Summary Label',
          description:
            'Label to be displayed on the results page pop up that summarizes the selected options',
        },
        {
          component: 'toggle',
          name: 'isMultipleChoice',
          label: 'Is Multiple Choice?',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: false,
        },
        {
          component: 'toggle',
          name: 'showOptionsImages',
          label: 'Show Options Images?',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: false,
        },
        {
          component: 'select',
          name: 'optionsColumns',
          label: 'Options Columns',
          options: [
            {
              label: '1 Column',
              value: 'grid-cols-1',
            },
            {
              label: '2 Columns',
              value: 'grid-cols-2',
            },
          ],
        },
        {
          component: 'group-list',
          name: 'options',
          label: 'Options',
          itemProps: {
            label: '{{item.label}}',
          },
          fields: [
            {
              component: 'text',
              name: 'label',
              label: 'Option Label',
            },
            {
              component: 'image',
              name: 'image',
              label: 'Option Image',
              description:
                '(Optional, used only if the option is set to show images), recommended image size: 180px x 180px',
            },
            {
              component: 'group-list',
              name: 'productList',
              label: 'Products',
              itemProps: {
                label: '{{item.product.data.title}}',
              },
              fields: [
                {
                  component: 'select',
                  name: 'routineType',
                  label: 'Routine Type',
                  options: [
                    {
                      label: 'Daily',
                      value: 'daily',
                    },
                    {
                      label: 'Specialized',
                      value: 'specialized',
                    },
                  ],
                  defaultValue: 'daily',
                },
                {
                  component: 'productSearch',
                  name: 'product',
                  label: 'Product',
                },
                {
                  component: 'textarea',
                  name: 'resultMessage',
                  label: 'Result Message',
                  description:
                    'Message to be shown next to the product after the customer completes the quiz',
                },
              ],
            },
            {
              component: 'textarea',
              name: 'answerMessage',
              label: 'Answer Message',
              description:
                'Message to be shown after the customer selects the option',
            },
            {
              component: 'toggle',
              name: 'hasFollowUpQuestion',
              label: 'Has Follow Up Question?',
              description:
                "(This option doesn't work with multi option question) If enabled, a follow up question will be shown after the customer selects this option, if a product hs been selected for this option it will be disregarded",
            },
            {
              component: 'select',
              name: 'followUpOptionsColumns',
              label: 'Options Columns',
              options: [
                {
                  label: '1 Column',
                  value: 'grid-cols-1',
                },
                {
                  label: '2 Columns',
                  value: 'grid-cols-2',
                },
              ],
            },
            {
              component: 'textarea',
              name: 'followUpMessage1',
              label: 'Message 1',
              description: 'Text to be shown before the question',
            },
            {
              component: 'textarea',
              name: 'followUpMessage2',
              label: 'Message 2',
              description: 'Text to be shown before the question',
            },
            {
              component: 'group-list',
              name: 'followUpOptions',
              label: 'Follow Up Question Options',
              itemProps: {
                label: '{{item.label}}',
              },
              fields: [
                {
                  component: 'text',
                  name: 'label',
                  label: 'Option Label',
                },
                {
                  component: 'image',
                  name: 'image',
                  label: 'Option Image',
                  description:
                    '(Optional, used only if the option is set to show images), recommended image size: 180px x 180px',
                },
                {
                  component: 'group-list',
                  name: 'productList',
                  label: 'Products',
                  itemProps: {
                    label: '{{item.product.data.title}}',
                  },
                  fields: [
                    {
                      component: 'select',
                      name: 'routineType',
                      label: 'Routine Type',
                      options: [
                        {
                          label: 'Daily',
                          value: 'daily',
                        },
                        {
                          label: 'Specialized',
                          value: 'specialized',
                        },
                      ],
                      defaultValue: 'daily',
                    },
                    {
                      component: 'productSearch',
                      name: 'product',
                      label: 'product',
                    },
                    {
                      component: 'textarea',
                      name: 'resultMessage',
                      label: 'Result Message',
                      description:
                        'Message to be shown next to the product after the customer completes the quiz',
                    },
                  ]
                },
                {
                  component: 'textarea',
                  name: 'answerMessage',
                  label: 'Answer Message',
                  description:
                    'Message to be shown after the customer selects the option',
                },
              ],
            },
          ],
          validate: {
            required: true,
          },
        },
        {
          component: 'text',
          name: 'helpTitle',
          label: 'Help Modal Title',
        },
        {
          component: 'group-list',
          name: 'helpContent',
          label: 'Help Modal content',
          fields: [
            {
              component: 'image',
              name: 'image',
              label: 'Image',
              description: 'Optional, recomended image size: 180px x 180px',
            },
            {
              component: 'markdown',
              name: 'body',
              label: 'Body content',
              description: 'Required, content to be displayed in the modal',
            },
          ],
        },
      ],
    },
    {
      component: 'group',
      name: 'mailGate',
      label: 'Mail Gate Settings',
      description: 'Settings to configure the mail gate',
      fields: [
        {
          component: 'toggle',
          name: 'enableMailGate',
          label: 'Enable Mail Gate',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: true,
        },
        {
          component: 'text',
          name: 'mailGateKlaviyoId',
          label: 'Mail Gate Klaviyo Id',
          description:
            'Klaviyo list ID to subscribe the customers to (required if mail gate is enabled)',
        },
        {
          component: 'markdown',
          name: 'message1',
          label: 'Message 1',
        },
        {
          component: 'markdown',
          name: 'message2',
          label: 'Message 2',
        },
        {
          component: 'text',
          name: 'mailGateSubmitText',
          label: 'Mail Gate Submit Text',
          description:
            "text for the email form submit button, if empty defaults to 'submit'",
          defaultValue: 'View my results',
        },
        {
          component: 'text',
          name: 'mailGateCTAText',
          label: 'Mail Gate skip CTA Text',
          description:
            "Text for the button to skip submitting the email, if empty defaults to 'no thanks, just show me my results.'",
          defaultValue: 'no thanks, just show me my results.',
        },
      ]
    },
    {
      component: 'text',
      name: 'resultTitle',
      label: 'Result Page Title',
      description:
        'Title displayed at the top of the result page, if not populated will not render',
    },
    {
      component: 'markdown',
      name: 'resultDescription',
      label: 'Result Page Description',
      description:
        'Information displayed below the title on the result page, if not populated will not render',
    },
    {
      component: 'text',
      name: 'baseRoutineTitle',
      label: 'Base Routine Title',
    },
    {
      component: 'markdown',
      name: 'baseRoutineDescription',
      label: 'Base Routine Description',
      description: 'Information displayed below the base routine title',
    },
    {
      component: 'text',
      name: 'specializedRoutineTitle',
      label: 'Specialized Routine Title',
    },
    {
      component: 'markdown',
      name: 'specializedRoutineDescription',
      label: 'Specialized Routine Description',
      description: 'Information displayed below the specialized routine title',
    },
    {
      component: 'toggle',
      name: 'addAllTop',
      label: 'Add All Products Button Position',
      toggleLabels: {
        true: 'Top',
        false: 'Bottom',
      },
      defaultValue: false,
    },
    {
      component: 'text',
      name: 'addAllLabel',
      label: 'Add all products label',
      description:
        'Label for the button that adds all recommended products at once, if not populated the button will default to "add all products"',
    },
    {
      component: 'toggle',
      name: 'enableSaveResults',
      label: 'Enable Save Results',
      description:
        'Adds a button to allow the customer to send their results to their email without subscribing to the newsletter',
    },
    {
      component: 'text',
      name: 'saveResultsLabel',
      label: 'Save Results Label',
      description:
        'Label for the button that saves the results, if not populated the button will default to "save my results"',
    },
    {
      component: 'toggle',
      name: 'desktopBannerLightText',
      label: 'Desktop Banner Text Color',
      defaultValue: true,
      toggleLabels: {
        true: 'Light',
        false: 'Dark',
      },
    },
    {
      component: 'text',
      name: 'resultBannerTitle',
      label: 'Banner Title',
    },
    {
      component: 'markdown',
      name: 'resultBannerBody',
      label: 'Banner Body Content',
    },
    {
      component: 'link',
      name: 'resultBannerLink',
      label: 'Banner CTA',
      description:
        'renders a button on the banner, if either piece is not populated the button will not render',
    },
    {
      component: 'image',
      name: 'bannerImageDesktop',
      label: 'Banner Image Desktop',
    },
    {
      component: 'image',
      name: 'bannerImageMobile',
      label: 'Banner Image Mobile',
    },
  ],
};