import { useEffect } from 'react';
import { useAnalytics, useScriptLoad, TRACKING_EVENTS } from './hooks';

export function FueledKlaviyoScript({ withKey: klaviyoPixelId }) {
  const { register, subscribe } = useAnalytics();
  const { status } = useScriptLoad(
    {
      id: 'klaviyo-script',
      src: `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPixelId}`,
      defer: true,
      waitFor: () => !!window?.klaviyo,
    },
    'head'
  );

  const ready = register('klaviyo');
  /* Specs:
   * https://developers.klaviyo.com/en/docs/javascript_api
   * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration
   */

  useEffect(() => {
    const identify = (event) => {
      // Identify a user
      if (event.detail.user_properties?.customer_email) {
        klaviyo.identify({
          email: event.detail.user_properties?.customer_email,
          first_name: event.detail.user_properties?.customer_first_name,
          last_name: event.detail.user_properties?.customer_last_name,
        });
      }
    };

    const viewItemListListener = (event) => {
      identify(event);

      klaviyo.push([
        'track',
        'Category View',
        {
          CategoryName: window.location.pathname,
          CategoryID: event.detail.ecommerce.collection_id,
        },
      ]);
    };

    const viewItemListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.detail.products;

      klaviyo.push([
        'track',
        'Viewed Product',
        {
          ProductName: product.name,
          ProductID: product.product_id,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          CompareAtPrice: product.price,
        },
      ]);

      klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.product_id,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            CompareAtPrice: product.compare_at_price,
          },
        },
      ]);
    };

    const addToCartListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.add.products;

      klaviyo.push([
        'track',
        'Added to Cart',
        {
          Name: product.name,
          ProductID: product.product_id,
          Categories: product.categories,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          Quantity: product.quantity,
          CompareAtPrice: product.price,
        },
      ]);

      klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.product_id,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            Quantity: product.quantity,
            CompareAtPrice: product.compare_at_price,
          },
        },
      ]);
    };

    const klaviyoFormsListener = (e) => {
      if (e.detail.type === 'submit') {
        window.dataLayer.push({
          event: 'dl_subscribe',
          formId: e.detail.formId,
          formTitle: e.detail.metaData.$source,
        });
      }
    };

    const viewSearchResultsListener = (event) => {
      identify(event);

      const products = event.detail.ecommerce.products.map((product) => ({
        Name: product.name,
        ProductID: product.product_id,
        Price: product.price,
        Categories: product.category,
      }));

      window?.klaviyo.push([
        'track',
        'Searched Site',
        {
          SearchTerm: event.detail.ecommerce.actionField.search_term,
          ReturnedResults: products.length,
          ReturnedProducts: products,
        },
      ]);
    };

    subscribe(TRACKING_EVENTS.VIEW_ITEM, viewItemListener);
    subscribe(TRACKING_EVENTS.VIEW_ITEM_LIST, viewItemListListener);
    subscribe(TRACKING_EVENTS.ADD_TO_CART, addToCartListener);
    subscribe(TRACKING_EVENTS.VIEW_SEARCH_RESULTS, viewSearchResultsListener);

    window.addEventListener('klaviyoForms', klaviyoFormsListener);

    ready();
    return () => {
      window.removeEventListener('klaviyoForms', klaviyoFormsListener);
    };
  }, [status]);

  return null;
}
