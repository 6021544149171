import { useEffect, useRef, useState } from "react";
import { useQuizContext } from "./QuizContext";
import { useKlaviyoApi } from "@hooks/useKlaviyoApi";
import { Loading } from "./Loading";
import { Markdown } from "@/snippets/globals/layout/Markdown";
import { ArrowLeft } from "@/snippets/icons/ArrowLeft";
import { useProductsFromHandles } from "@backpackjs/storefront";

export function QuizMailGate() {
  const form = useRef();
  const email = useRef();
  const context = useQuizContext();
  const {
    quizSettings,
    quizData,
    updateQuizData,
    parseQuizResultUrl,
    getResultsProducts,
    buildSummaryResults,
  } = context;
  const [messages, setMessages] = useState([]);
  const [messagesToPrint, setMessagesToPrint] = useState(() => {
    const messages = [];

    if(!!quizSettings.mailGateSettings?.message1) {
      messages.push(quizSettings.mailGateSettings?.message1);
    }
    if(!!quizSettings.mailGateSettings?.message2) {
      messages.push(quizSettings.mailGateSettings?.message2);
    }

    return messages;
  });
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { subscribeToList, sendEvent } = useKlaviyoApi();
  let resultProducts = getResultsProducts().filter((result) => typeof(result) !== 'undefined');
  const { products: parsedProducts } = useProductsFromHandles({version: 'full', handles: resultProducts.map((productObject) => productObject?.product?.handle)});
  if(parsedProducts?.length > 0) {
    resultProducts = resultProducts.map((resultObject) => {
      const updatedProduct = parsedProducts.find((product) => product.handle === resultObject?.product?.handle);
      return {
        ...resultObject,
        product: updatedProduct || null,
      }
    })
  }

  const quizSubscribe = async (e) => {
    e.preventDefault();

    const results = quizData.answers.filter((answer) => answer);
    const currentSearchParams = new URLSearchParams('step=results');
    const parsedResults = results.map((result) => {
      if(result) {
        return `${result.questionIndex}:${Array.isArray(result.answer) ? result.answer.join(',') : result.answer}`
      }
    })
    parsedResults.forEach((result) => {
      currentSearchParams.append('responses', result)
    })
    const customerProperties = buildSummaryResults();
    customerProperties = customerProperties.reduce((previousObject, currentObject) => {
      return Object.assign(previousObject, {[currentObject.label.toLowerCase().replaceAll(' ', '_')]: currentObject.answer})
    }, {});

    await subscribeToList({
      listId: quizSettings.mailGateSettings.mailGateKlaviyoId,
      email: document.querySelector('input[name="quiz-email"]').value,
      source: 'Quiz Mail Gate',
    })

    await sendEvent({
      eventName: 'Quiz Mail Gate Results',
      metricName: 'Quiz Save Results',
      email: document.querySelector('input[name="quiz-email"]').value,
      eventProperties: {
        quizResultUrl: parseQuizResultUrl(),
        products: resultProducts.map((resultObject) => {
          const customCollectionMetafield = resultObject?.product?.metafields?.find((metafield) => metafield.key === 'custom_collection_title');
          const customTitleMetafield = resultObject?.product?.metafields?.find((metafield) => metafield.key === 'custom_product_title');
          return {
            title: customTitleMetafield?.value || resultObject?.product?.title,
            collection: customCollectionMetafield?.value || '',
            url: resultObject?.product?.onlineStoreUrl,
            image: resultObject?.product?.featuredImage?.src,
            problem: resultObject?.resultMessage,
          }
        })
      },
      profileProperties: customerProperties,
    }).then((response) => {
      if(response.errors) {
        setHasError(true);
      } else {
        updateQuizData({
          quizStep: 'results',
        })
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if(messages.length < messagesToPrint.length) {
        setMessages(messagesToPrint.slice(0, messages.length + 1));
        if(messages.length + 1 === messagesToPrint.length) {
          setLoading(false);
        }
      }
    }, 2000)

  }, [messages]);

  return (
    <>
      <div className="quiz-mail-gate__container mx-auto w-full max-w-2xl px-5">
      <div className="quiz-steps__back-button w-full pt-3 lg:pt-6">
        {quizData.currentQuestion > 0 && (
          <button
            type="button"
            className="flex items-center font-gothamBold"
            onClick={() => {
              updateQuizData({
                quizStep: "steps",
                hasError: false,
              })
            }}
          >
            <ArrowLeft className="leading-none" />
            <span className="ml-2 inline-block">Back</span>
          </button>
        )}
      </div>
        <div className="quiz-mail-gate__body-content mt-3 lg:mt-6 mb-10 flex min-h-[540px] flex-col rounded-[20px] border border-grey-4 px-4 py-5 lg:px-12 lg:py-8">
          {messages.length > 0 &&
            messages.map((message, index) => {
              return (
                <div
                  key={`message-${index}`}
                  className={`mb-5 max-w-[80%] rounded-[20px] rounded-bl-none px-4 pt-3 pb-4 ${quizSettings.textColor} ${quizSettings.bgColor}`}
                >
                  <Markdown content={message} />
                </div>
              );
            })}
          {loading && <Loading />}
          {!loading && messages.length === messagesToPrint.length && (
            <>
              <form
                className="mail-gate__form"
                ref={form}
                id="subscribeForm"
                onSubmit={quizSubscribe}
              >
                <input
                  type="email"
                  name="quiz-email"
                  placeholder="E-mail Address"
                  label="E-mail Address"
                  ref={email}
                  className="border-grey-5 placeholder-black border w-full py-2 px-4"
                  onChange={() => {
                    if(hasError) {
                      setHasError(false);
                    }
                  }}
                />
                {hasError && (
                  <p className="text-error text-left">There has been an error with your submission, please try again</p>
                )}
              </form>
              <div className="quiz-mail-gate__footer mt-auto w-full">
                <div className="text-center">
                  <button
                    className="mb-8 focus-link mx-auto !border-b-[1px] !font-primary"
                    onClick={() => {
                      updateQuizData({
                        quizStep: 'results',
                      });
                    }}
                  >
                    {quizSettings.mailGateSettings.mailGateCTAText}
                  </button>
                </div>
                <button
                  type="submit"
                  className="button button--primary w-full"
                  form="subscribeForm"
                >
                  {quizSettings.mailGateSettings.mailGateSubmitText || 'submit'}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}