import { newLineToBr } from '@/utils/Helper';

function Section({ cms, collection }) {
  let hasBackgroundImage = false;

  if (cms.desktop_image && cms.mobile_image) {
    hasBackgroundImage = true;
  }

  return (
    <section
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp="CollectionBanner"
      className={`collection-banner relative flex flex-col justify-center overflow-hidden ${
        !cms.isFullwidth ? 'container' : ''
      } ${!hasBackgroundImage ? 'py-10' : ''} ${cms.textColor} ${
        cms.textColor_m
      }`}
    >
      {collection ? (
        <>
          <style>
            {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.mobileSpacing}px;
            }
            @media(min-width: 1024px) {
              #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
                margin-top: ${cms.desktopSpacing}px;
              }
            }`}
          </style>
          {hasBackgroundImage && (
            <picture className="t-0 l-0 relative h-full w-full object-cover object-center">
              <source
                srcSet={cms.desktop_image.src}
                media="(min-width: 768px)"
                width="2880"
                height="800"
              />
              <img
                src={cms.mobile_image.src}
                alt={cms?.image_alt}
                loading="lazy"
                className="h-full w-full object-cover object-center"
                width="750"
                height="320"
              />
            </picture>
          )}
          <div
            className={`${cms.isFullwidth ? 'container' : 'w-full'}
            ${
              hasBackgroundImage
                ? 'absolute top-4 lg:top-1/2 lg:-translate-y-1/2'
                : 'static'
            } mx-auto px-4 lg:px-16`}
          >
            <h1
              key="banner-title"
              className="banner-title h2 mb-4 max-w-[432px]"
            >
              {cms.banner_title || collection.title}
            </h1>
            {(cms.banner_description || collection.banner_description) && (
              <div
                key="banner-description"
                className="banner-description max-w-[432px] text-h6-m md:text-h6"
                dangerouslySetInnerHTML={{
                  __html: newLineToBr(
                    cms.banner_description || collection.description
                  ),
                }}
              />
            )}
          </div>
        </>
      ) : (
        <h1 className="container relative my-20 w-full rounded-xl border border-error-state py-20 px-10 text-center font-sans text-base text-black after:absolute after:top-0 after:left-0 after:h-full after:w-full after:rounded-xl after:bg-error after:opacity-20">
          <span className="z-1 relative">
            <b>Collection Grid: </b>This section <b>only</b> works on a
            collection template
          </span>
        </h1>
      )}
    </section>
  );
}

Section.displayName = 'Collection Banner';

Section.Schema = {
  category: 'Collection',
  label: Section.displayName,
  key: 'collectionBanner',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'isFullwidth',
      label: 'Full Width',
      toggleLabels: {
        true: 'Full Width',
        false: 'Grid width',
      },
      defaultValue: true,
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color (Desktop)',
      options: [
        { label: 'Light', value: 'md:text-white' },
        { label: 'Dark', value: 'md:text-black' },
      ],
      defaultValue: 'md:text-black',
    },
    {
      component: 'select',
      name: 'textColor_m',
      label: 'Text Color (Mobile)',
      options: [
        { label: 'Light', value: 'text-white' },
        { label: 'Dark', value: 'text-black' },
      ],
      defaultValue: 'text-black',
    },
    {
      name: 'banner_title',
      label: 'Banner Title',
      component: 'text',
    },
    {
      name: 'banner_description',
      label: 'Banner Description',
      component: 'textarea',
    },
    {
      name: 'desktop_image',
      label: 'Desktop Image',
      component: 'image',
      description: 'Recommended Image Size: 2880px x 800px',
    },
    {
      name: 'mobile_image',
      label: 'Mobile Image',
      component: 'image',
      description: 'Recommended Image Size: 750px x 320px',
    },
    {
      name: 'image_alt',
      label: 'Image alt text',
      component: 'text',
    },
  ],
};

export const CollectionBanner = Section;
