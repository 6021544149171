import Slider from 'react-slick';
import { newLineToBr } from '@/utils/Helper';

export function ThreeUp({ cms }) {
  let slider = null;

  const next = () => {
    if (slider) slider.slickNext();
  };
  const prev = () => {
    if (slider) slider.slickPrev();
  };

  const getCTAclass = (style) => {
    if (style === 'link') return 'focus-link';
    if (style === 'white-link') return 'focus-link-white';
    if (style === 'primary') return 'no-underline button button--primary';
    if (style === 'secondary') return 'no-underline button button--secondary';
    if (style === 'tertiary') return 'no-underline button button--tertiary';
    return '';
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 2.3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.6,
        },
      },
    ],
  };

  return (
    <div
      id={`${ThreeUp.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={ThreeUp.displayName}
      className={`three-up flex flex-wrap items-center py-8 lg:py-12 ${cms.bgColor} ${cms.fgColor}`}
    >
      <style>
        {`#${ThreeUp.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${ThreeUp.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="mb-6 w-full px-4 lg:mb-0 lg:max-w-[390px] lg:pl-12 lg:pr-16">
        {cms?.intro && (
          <p className="mb-4 font-gothamBold text-h6">{cms.intro}</p>
        )}
        {cms?.heading && (
          <h2 className="text-header mb-1.5 lg:mb-3">{cms.heading}</h2>
        )}
        {cms?.body && (
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{ __html: newLineToBr(cms.body) }}
          />
        )}
        <div className="hidden lg:mt-10 lg:flex">
          <button
            type="button"
            className="three-up__arrow three-up__arrow--prev"
            onClick={prev}
          >
            <svg
              width="11"
              height="17"
              viewBox="0 0 11 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 2L2.25 8.5L8.75 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          </button>
          <button
            type="button"
            className="three-up__arrow three-up__arrow--next -ml-px"
            onClick={next}
          >
            <svg
              width="11"
              height="17"
              viewBox="0 0 11 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 2L8.75 8.5L2.25 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="w-full lg:max-w-[calc(100%-390px)]">
        {cms?.columns?.length > 0 && (
          <Slider ref={(c) => (slider = c)} {...settings}>
            {cms.columns.map((column, columnIdx) => (
              <div
                className="px-3 focus:outline-none"
                key={`column-item-${columnIdx}`}
              >
                {column?.image && (
                  <img
                    className="mb-3 lg:mb-5 xl:mb-4"
                    src={column.image.src}
                    alt={column.image_alt}
                    loading="lazy"
                    width="864"
                    height="1022"
                  />
                )}
                {column?.title && <h6 className="mb-1.5">{column.title}</h6>}
                {column?.description && (
                  <p
                    className="text-xsmall mb-3"
                    dangerouslySetInnerHTML={{
                      __html: newLineToBr(column.description),
                    }}
                  />
                )}
                {column?.cta_link?.text && (
                  <a
                    href={column.cta_link.url}
                    className={`${getCTAclass(
                      column.cta_style
                    )} inline-block text-[14px] leading-[22px]`}
                  >
                    {column.cta_link.text}
                  </a>
                )}
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div className="mt-5 flex px-4 lg:hidden">
        <button
          type="button"
          className="three-up__arrow three-up__arrow--mobile three-up__arrow--prev"
          onClick={prev}
        >
          <svg
            width="11"
            height="17"
            viewBox="0 0 11 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.75 2L2.25 8.5L8.75 15"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
        </button>
        <button
          type="button"
          className="three-up__arrow three-up__arrow--mobile three-up__arrow--next -ml-px"
          onClick={next}
        >
          <svg
            width="11"
            height="17"
            viewBox="0 0 11 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.25 2L8.75 8.5L2.25 15"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

ThreeUp.displayName = 'ThreeUp';

ThreeUp.Schema = {
  category: 'Modules',
  label: ThreeUp.displayName,
  key: 'ThreeUp',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'select',
      description:
        'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-white',
    },
    {
      name: 'fgColor',
      label: 'Foreground Color',
      component: 'select',
      description:
        'For section foreground color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'text-white' },
        { label: 'Dark', value: 'text-grey-1' },
      ],
      defaultValue: 'text-grey-1',
    },
    {
      name: 'intro',
      label: 'Intro Title',
      component: 'text',
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'body',
      label: 'Body',
      component: 'textarea',
    },
    {
      name: 'columns',
      label: 'Columns',
      component: 'group-list',
      fields: [
        {
          name: 'image',
          label: 'Image',
          component: 'image',
          description: 'Recommended Image Size: 864px x 1022px',
        },
        {
          name: 'image_alt',
          label: 'Image alt text',
          component: 'text',
        },
        {
          name: 'title',
          label: 'Title',
          component: 'text',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'textarea',
        },
        {
          name: 'cta_link',
          label: 'CTA Link',
          component: 'link',
        },
        {
          name: 'cta_style',
          label: 'CTA Link Style',
          component: 'select',
          options: [
            { label: 'Primary', value: 'primary' },
            { label: 'Secondary', value: 'secondary' },
            { label: 'Tertiary', value: 'tertiary' },
            { label: 'Focus Link', value: 'link' },
            { label: 'Focus Link White', value: 'white-link' },
          ],
          defaultValue: 'link',
        },
      ],
    },
  ],
};
