import { useEffect } from 'react';
import { Close } from '@/snippets/icons/Close';
import { newLineToBr } from '@/utils/Helper';
import Styles from './hotspot.module.css';
import { useWindowDimensions } from '@/utils/Helper';

export function Hotspot(props) {
  const hotspot = props?.data;
  if (!hotspot) return;
  const { vpWidth } = useWindowDimensions();
  const isMobile = vpWidth < 1024;

  const getLabelclass = (style) => {
    if (style === 'dark-label') return 'dark-label';
    if (style === 'light-label') return 'light-label';
    if (style === 'pink-label') return 'pink-label';
    return '';
  };

  let x_pos = hotspot?.x_pos ? hotspot.x_pos : 0;
  let y_pos = hotspot?.y_pos ? hotspot.y_pos : 0;
  if (x_pos < 0) x_pos = 0;
  if (y_pos < 0) y_pos = 0;
  if (x_pos > 100) x_pos = 100;
  if (y_pos > 100) y_pos = 100;

  let x_pos_m = hotspot?.x_pos_m ? hotspot.x_pos_m : x_pos;
  let y_pos_m = hotspot?.y_pos_m ? hotspot.y_pos_m : y_pos;
  if (x_pos_m < 0) x_pos_m = 0;
  if (y_pos_m < 0) y_pos_m = 0;
  if (x_pos_m > 100) x_pos_m = 100;
  if (y_pos_m > 100) y_pos_m = 100;

  const d_styles = { top: `${y_pos}%`, left: `${x_pos}%` };
  const m_styles = { top: `${y_pos_m}%`, left: `${x_pos_m}%` };

  const openModal = (e) => {
    const modal = e.target
      ?.closest('.hotspot')
      ?.querySelector('.hotspot-modal');
    if (!modal) return;
    const copy = modal.cloneNode(true);
    document.body.appendChild(copy);
    copy.classList.remove('hidden');
  };

  const closeModal = (modal) => {
    if (!modal) return;
    modal.remove();
  };

  useEffect(() => {
    document.body.addEventListener(
      'click',
      (e) => {
        const closeBtn = e.target.closest('.hotspot-modal-close');
        if (closeBtn) {
          closeModal(closeBtn.closest('.hotspot-modal'));
        } else if (e.target.classList.contains('hotspot-modal')) {
          closeModal(e.target);
        }
      },
      true
    );
  }, []);

  return (
    <div className={`${getLabelclass(
        hotspot.style
      )} hotspot h-0 w-0`}>
      <div className="absolute h-0 w-0" style={isMobile ? m_styles : d_styles}>
        <button
          className="m-[-6px] flex h-3 w-3 lg:h-4 lg:w-4 lg:-m-2 hotspot-button bg-grey-1 items-center justify-center rounded-full relative"
          onClick={(e) => openModal(e)}
          type="button"
        >
          <div className="sr-only" aria-label={`Open modal for ${hotspot?.label}`}>{hotspot?.label}</div>
          <span className={`absolute left-1/2 top-1/2 h-5 w-5 rounded-full border hotspot-pulse border-grey-1 ${Styles.pulse}`} />
        </button>
      </div>
      <div className="absolute hidden h-0 w-0 lg:block" style={isMobile ? m_styles : d_styles}>
        {hotspot?.label_direction === 'left' && (
          <div className="hotspot-cta right-0 -translate-y-1/2 flex-row">
            <button type="button" onClick={(e) => openModal(e)} aria-label={`Open modal for ${hotspot?.label}`}>{hotspot?.label}</button>
            <span className="h-0.5 w-[60px]" />
          </div>
        )}
        {hotspot?.label_direction === 'right' && (
          <div className="hotspot-cta left-0 -translate-y-1/2 flex-row">
            <button type="button" onClick={(e) => openModal(e)} aria-label={`Open modal for ${hotspot?.label}`}>{hotspot?.label}</button>
            <span className="order-first h-0.5 w-[60px]" />
          </div>
        )}
        {hotspot?.label_direction === 'top' && (
          <div className="hotspot-cta bottom-0 -translate-x-1/2 flex-col">
            <button type="button" onClick={(e) => openModal(e)} aria-label={`Open modal for ${hotspot?.label}`}>{hotspot?.label}</button>
            <span className="h-[60px] w-0.5" />
          </div>
        )}
        {hotspot?.label_direction === 'bottom' && (
          <div className="hotspot-cta top-0 -translate-x-1/2 flex-col">
            <button type="button" onClick={(e) => openModal(e)} aria-label={`Open modal for ${hotspot?.label}`}>{hotspot?.label}</button>
            <span className="order-first h-[60px] w-0.5" />
          </div>
        )}
        {hotspot?.modal_heading && hotspot?.modal_body && (
          <div className="hotspot-modal fixed inset-0 hidden h-full w-full p-4 z-[2001]">
            <div className="flex h-full w-full items-center justify-center">
              <div className="relative w-full max-w-[435px] bg-white px-[30px] py-[34px]">
                <button className="hotspot-modal-close absolute top-4 right-4 flex h-5 w-5 items-center justify-center"
                  aria-label="hotspot modal close"
                >
                  <Close />
                </button>
                <h6 className="mb-3">{hotspot.modal_heading}</h6>
                <div
                  className="hotspot-modal-body"
                  dangerouslySetInnerHTML={{
                    __html: newLineToBr(hotspot.modal_body),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
