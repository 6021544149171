import { Image } from "@/snippets/components/Image";
import { SliderArrow } from "@/snippets/icons/SliderArrow";
import { BeforeAfterProductModal } from "./BeforeAfterProductModal";
import { Swap } from "@/snippets/icons/Swap";
import { useRef, useState } from "react";
import Slider from "react-slick";
import { useWindowDimensions } from "@/utils/Helper";

export function BeforeAfterSlider({ cms }) {
  const {
    mobileSpacing,
    desktopSpacing,
    heading,
    body,
    whiteLabel,
    imageRight,
    bgImageLabel,
    fgImageLabel,
    viewProductsCtaLabel,
    viewProductsCtaIcon,
    previewList,
  } = cms;

  const [productModalOpen, setProductModalOpen] = useState(false);
  const backgroundImage = useRef(null);
  const foregroundImage = useRef(null);
  const sliderDivider = useRef(null);
  const { vpWidth } = useWindowDimensions();
  const isMobile = vpWidth < 1024;

  const [currentPreview, setCurrentPreview] = useState(previewList[0]);

  const updateForegroundImageWidth = (e) => {
    const value = e.target.value;
    foregroundImage.current.style.width = `${100 - value}%`;
    sliderDivider.current.style.left = `${value}%`;
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 5.1,
    mobileFirst: true,
    spaceBetween: 20,
    responsive: [
      {
        breakpoint: 662,
        settings: {
          slidesToShow: 4.2,
        },
      }
    ]
  }

  return (
    <section
      id={`${BeforeAfterSlider.Schema.key}-${
        cms.id || cms.cmsId || cms.tinaId
      }`}
      className={`before-after-slider container mx-auto items-center justify-center px-4 lg:flex ${
        imageRight == true ? 'lg:flex-row-reverse' : 'lg:flex-row'
      }`}
    >
      <style>
        {`#${BeforeAfterSlider.Schema.key}-${
          cms.id || cms.cmsId || cms.tinaId
        } {
            margin-top: ${mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${BeforeAfterSlider.Schema.key}-${
          cms.id || cms.cmsId || cms.tinaId
        } {
              margin-top: ${desktopSpacing}px;
            }
          }
        #${BeforeAfterSlider.Schema.key}-${
          cms.id || cms.cmsId || cms.tinaId
        } .slick-track {
          display: flex;
          padding: 0 20px;
        }
        `}
      </style>
      <div className="before-after-slider__image-container lg:w-5/12">
        {isMobile && heading && (
          <h2 className="before-after-slider__heading h4 mb-4 font-tiempos">
            {heading}
          </h2>
        )}
        <div className="before-after-slider__slider-container relative aspect-square cursor-not-allowed overflow-hidden border border-black">
          {bgImageLabel && (
            <span
              className={`absolute top-4 left-5 z-10 font-gothamBold text-lg ${
                currentPreview?.bgWhiteLabel == true ? 'text-white' : ''
              }`}
            >
              {bgImageLabel}
            </span>
          )}
          {fgImageLabel && (
            <span
              className={`absolute top-4 right-5 z-10 font-gothamBold text-lg ${
                currentPreview?.fgWhiteLabel == true ? 'text-white' : ''
              }`}
            >
              {fgImageLabel}
            </span>
          )}
          <div
            className="before-after-slider__background-image"
            ref={backgroundImage}
          >
            {currentPreview?.backgroundImage && (
              <Image
                key={'image-left'}
                className="w-full object-cover object-left"
                src={currentPreview?.backgroundImage?.src}
                width={currentPreview?.backgroundImage?.width}
                height={currentPreview?.backgroundImage?.height}
                alt={currentPreview?.backgroundImage?.altText}
                quality="100"
                loading="lazy"
              />
            )}
          </div>
          <div
            className="before-after-slider__foreground-image absolute top-0 right-0 h-full w-1/2 overflow-hidden"
            ref={foregroundImage}
          >
            {currentPreview?.foregroundImage && (
              <Image
                className="h-full w-full object-cover object-right"
                key={'image-right'}
                src={currentPreview?.foregroundImage?.src}
                width={currentPreview?.foregroundImage?.width}
                height={currentPreview?.foregroundImage?.height}
                alt={currentPreview?.foregroundImage?.altText}
                quality="100"
                loading="lazy"
              />
            )}
          </div>
          <div
            className="before-after-slider__divider absolute top-0 left-1/2 block h-full w-[3px] bg-white"
            ref={sliderDivider}
          >
            <SliderArrow className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
          </div>
          <input
            type="range"
            className="absolute top-0 h-full w-full appearance-none opacity-0"
            min={6}
            max={94}
            defaultValue={50}
            onChange={updateForegroundImageWidth}
            aria-label="Slider to compare images"
          />
          {currentPreview?.products && (
            <button
              className="before-after-slider__cta absolute bottom-[-1px] right-[-1px] border border-black bg-white px-5 py-2.5 font-gothamBold"
              onClick={() => setProductModalOpen(true)}
              aria-label={viewProductsCtaLabel}
            >
              {viewProductsCtaIcon && (
                <Image
                  src={viewProductsCtaIcon.src}
                  width="20"
                  height="20"
                  className="mr-4 inline-block h-5 w-5"
                  alt=""
                />
              )}
              {viewProductsCtaLabel}
            </button>
          )}
        </div>
      </div>
      <div
        className={`before-after-slider__body-container lg:w-5/12 ${
          imageRight == true ? 'lg:pr-16' : 'lg:pl-16'
        } flex flex-col`}
      >
        {!isMobile && heading && (
          <h2 className="before-after-slider__heading h4 mb-4 font-tiempos">
            {heading}
          </h2>
        )}
        <div
          className="before-after-slider__body font-gothamBook order-4 mb-4 mt-2 lg:mt-0 text-base lg:order-2"
          dangerouslySetInnerHTML={{ __html: body }}
        />
        {previewList.length > 1 && (
          <>
            <div className="order-1 mt-4 font-gothamBold text-sm lg:order-3 lg:mt-2">
              <Swap className="mr-1.5 inline-block" />
              Swap to see additional results
            </div>
            {isMobile ? (
              <Slider
                {...settings}
                className="order-2 mt-4 -mr-4 -ml-5 lg:order-4"
              >
                {previewList.map((preview, index) => (
                  <div className="pr-2.5">
                    <button
                      className={`before-after-slider__thumbnail relative aspect-square cursor-pointer border ${
                        preview == currentPreview
                          ? 'border-primary-2'
                          : 'border-black'
                      }`}
                      key={preview?.backgroundImage?.src}
                      onClick={() => setCurrentPreview(preview)}
                      aria-label={preview?.foregroundImage?.altText || `Thumbnail ${index + 1}`}
                    >
                      <Image
                        className="h-full w-full object-cover"
                        src={`${preview?.foregroundImage?.src}`}
                        width={110}
                        height={110}
                        alt={preview?.foregroundImage?.altText || `Thumbnail ${index + 1}`}
                        loading="lazy"
                      />
                    </button>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="order-2 mt-4 grid max-w-md grid-cols-4 gap-2.5 lg:order-4">
                {previewList.map((preview, index) => (
                  <button
                    className={`before-after-slider__thumbnail relative aspect-square cursor-pointer border ${
                      preview == currentPreview
                        ? 'border-primary-2'
                        : 'border-black'
                    }`}
                    key={preview?.backgroundImage?.src}
                    onClick={() => setCurrentPreview(preview)}
                    aria-label={preview?.foregroundImage?.altText || `Thumbnail ${index + 1}`}
                  >
                    <Image
                      className="h-full w-full object-cover"
                      src={`${preview?.foregroundImage?.src}`}
                      width={110}
                      height={110}
                      alt={preview?.foregroundImage?.altText || `Thumbnail ${index + 1}`}
                      loading="lazy"
                    />
                  </button>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {productModalOpen && (
        <BeforeAfterProductModal
          products={currentPreview.products}
          title={currentPreview?.modalTitle}
          setProductModalOpen={setProductModalOpen}
        />
      )}
    </section>
  );
}

BeforeAfterSlider.displayName = 'Before After Slider';

BeforeAfterSlider.Schema = {
  category: 'Modules',
  label: BeforeAfterSlider.displayName,
  key: 'BeforeAfterSlider',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'body',
      label: 'Body',
      description: 'This holds paragraph text and ordered/unordered list',
      component: 'markdown',
    },
    {
      name: 'imageRight',
      label: 'Image Position',
      component: 'toggle',
      toggleLabels: {
        true: 'Right',
        false: 'Left',
      },
      defaultValue: false,
      description: 'Select the position of the image in the layout',
    },
    {
      name: 'bgImageLabel',
      label: 'Background Image Label',
      description: 'Left area image label',
      component: 'text',
    },
    {
      name: 'fgImageLabel',
      label: 'Foreground Image Label',
      description: 'Right area image label',
      component: 'text',
    },
    {
      name: 'viewProductsCtaIcon',
      label: 'View Products CTA Icon',
      component: 'image',
      description: 'Icon for the button that will show the list of products from the current thumbnail (if populated)',
    },
    {
      name: 'viewProductsCtaLabel',
      label: 'View Products CTA Label',
      component: 'text',
      description: 'Label for the button that will show the list of products from the current thumbnail (if populated)',
    },
    {
      name: 'previewList',
      label: 'Preview list',
      component: 'group-list',
      fields: [
        {
          name: 'backgroundImage',
          label: 'Background Image Image',
          component: 'image',
          description: 'Recommended image size: 600x600px',
        },
        {
          name: 'bgWhiteLabel',
          label: 'Background Image Label Color',
          component: 'toggle',
          toggleLabels: {
            true: 'White',
            false: 'Black',
          },
        },
        {
          name: 'foregroundImage',
          label: 'Foreground Image Image',
          component: 'image',
          description: 'Recommended image size: 600x600px (Used as the default image for the thumbnails)',
        },
        {
          name: 'fgWhiteLabel',
          label: 'Foreground Image Label Color',
          component: 'toggle',
          toggleLabels: {
            true: 'White',
            false: 'Black',
          },
        },
        {
          name: 'modalTitle',
          label: 'Modal Title',
          component: 'text',
          description: 'Heading for the modal that will show the list of products from the current preview if not populated the modal will have no heading',
        },
        {
          name: 'products',
          label: 'Products Used',
          component: 'group-list',
          description: 'List of products used for the current item, if none is provided, the button to view products will not be shown',
          fields: [
            {
              name: 'product',
              label: 'Product',
              component: 'productSearch',
            },
            {
              name: 'current',
              label: 'Is current product?',
              component: 'toggle',
              description: "Only one product can be marked as current, if more than one is marked as current, the first one will be used, also the current product will allways become first on the list"
            }
          ],
          validate: {
            maxItems: 4
          }
        }
      ]
    }

  ]
}