import React, { useEffect, useRef } from 'react';
import { newLineToBr } from '@/utils/Helper';

export function ParallaxStory({ cms }) {
  const storyContainer = useRef();
  const isElementInViewport = (el) => {
    // check if an element is past 60% of the viewport.
    const rect = el.getBoundingClientRect();

    return rect.top < window.outerHeight * 0.55;
  };

  useEffect(() => {
    const items = storyContainer.current.querySelectorAll('.story-container');

    function callbackFunc() {
      items.forEach((item, index) => {
        if (isElementInViewport(item)) {
          const moduleHeight = item.offsetHeight;
          const visibleArea =
            window.outerHeight * 0.55 - item.getBoundingClientRect().top;
          const barHeight = (visibleArea * 100) / moduleHeight;
          item.querySelector('.storyBar').style.height =
            barHeight > 100 ? '100%' : `${barHeight}%`;

          item.classList.add('in-view');
        } else {
          item.classList.remove('in-view');
        }
      });
    }

    // listen for events
    if (typeof window !== 'undefined') {
      window.addEventListener('load', callbackFunc);
      window.addEventListener('resize', callbackFunc);
      window.addEventListener('scroll', callbackFunc);
    }

    return () => {
      window.removeEventListener('load', callbackFunc);
      window.removeEventListener('resize', callbackFunc);
      window.removeEventListener('scroll', callbackFunc);
    };
  });

  return (
    <section
      id={`${ParallaxStory.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={ParallaxStory.displayName}
      className="parallax-story-container overflow-x-hidden"
      ref={storyContainer}
    >
      <style>
        {`#${ParallaxStory.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${ParallaxStory.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="m-auto mb-[47px] max-w-[300px] text-center lg:mb-[165px] lg:max-w-[430px]">
        {cms?.section_title && (
          <h2 className="text-header mb-3 px-5 lg:mb-4">
            {cms?.section_title}
          </h2>
        )}
        {cms?.section_description && (
          <p
            className="mx-auto mb-0 px-5 font-primary text-base font-light lg:text-lg"
            dangerouslySetInnerHTML={{
              __html: newLineToBr(cms?.section_description),
            }}
          />
        )}
      </div>
      <div className="story-outer-container mx-auto w-full">
        {cms?.stories?.map((stories, index) => {
          return (
            <div
              key={`${cms.id}-story-${index}`}
              className="story-container group"
            >
              <span className="storyBar lg:margin-auto absolute left-0 top-0 block w-1 bg-quinary-1 lg:left-auto" />
              <div className="story-inner-container opacity-0 transition-opacity duration-300 ease-in-out group-[.in-view]:opacity-100">
                {stories?.story_pre_title && (
                  <p className="mb-2 font-gothamBold text-base leading-none text-grey-1">
                    {stories?.story_pre_title}
                  </p>
                )}
                {stories?.story_title && (
                  <p className="text-large mb-1.5 font-tiempos sm:text-h4 md:mb-1">
                    {stories?.story_title}
                  </p>
                )}
                {stories?.story_description && (
                  <p
                    className="mb-8 text-[14px] font-light leading-[22px] sm:text-[16px] sm:leading-[24px]"
                    dangerouslySetInnerHTML={{
                      __html: newLineToBr(stories?.story_description),
                    }}
                  />
                )}
                {stories?.story_image && (
                  <img
                    src={stories?.story_image.src}
                    className="story-image h-full w-full max-w-[240px] object-cover lg:max-w-[288px]"
                    alt={stories?.image_alt}
                    loading="lazy"
                    width="820"
                    height="900"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

ParallaxStory.displayName = 'ParallaxStory';

ParallaxStory.Schema = {
  category: 'Modules',
  label: ParallaxStory.displayName,
  key: 'ParallaxStory',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'text',
      label: 'Section Title',
      name: 'section_title',
    },
    {
      component: 'textarea',
      label: 'Section Description',
      name: 'section_description',
    },
    {
      name: 'stories',
      label: 'Stories',
      component: 'group-list',
      fields: [
        {
          component: 'image',
          label: 'Story Image',
          name: 'story_image',
          description: 'Recommended size: 820px x 900px',
        },
        {
          name: 'image_alt',
          label: 'Image alt text',
          component: 'text',
        },
        {
          component: 'text',
          name: 'story_pre_title',
          label: 'Story Pre-title',
        },
        {
          component: 'text',
          label: 'Story Title',
          name: 'story_title',
        },
        {
          component: 'textarea',
          label: 'Story Description',
          name: 'story_description',
        },
      ],
    },
  ],
};
