import { useEffect, useCallback, useState } from 'react';

import { useScriptLoad } from './hooks';
import { useCustomerPrivacy } from './hooks/useCustomerPrivacy';

export const useGetOneTrustConsent = () => {
  const [consent, setConsent] = useState(null);

  useEffect(() => {
    const consentCollectedHandler = (event) => {
      if (window.OneTrust) {
        const consent = event.detail;

        setConsent({
          marketing: consent?.firstPartyMarketingAllowed,
          analytics: consent?.analyticsAllowed,
          preferences: consent?.preferencesAllowed,
          sale_of_data: consent?.thirdPartyMarketingAllowed
        });
      }
    };
    document.addEventListener('visitorConsentCollected', consentCollectedHandler);

    return () => {
      document.removeEventListener('visitorConsentCollected', consentCollectedHandler);
    };
  }, []);

  return consent;
};

export const FueledOneTrustScript = ({ withKey: openTrustId }) => {
  const { status } = useScriptLoad({
    id: 'fueled-open-trust',
    charset: 'UTF-8',
    src: `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`,
    'data-domain-script': openTrustId,
  }, 'head');

  const { customerPrivacy } = useCustomerPrivacy({
    checkoutRootDomain: process.env.SHOPIFY_CART_DOMAIN,
    storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_API_TOKEN
  });

  const oneTrustConsentCallback = useCallback(() => {
    if (!customerPrivacy || !customerPrivacy?.setTrackingConsent) {
      return;
    }

    const trackingConsent = {
      marketing: getOneTrustConsentStatus('C0004'),
      analytics: getOneTrustConsentStatus('C0002'),
      preferences:
        getOneTrustConsentStatus('C0001') ||
        getOneTrustConsentStatus('C0003'),
      sale_of_data:
        getOneTrustConsentStatus('C0002') &&
        getOneTrustConsentStatus('C0004'),
    };

    customerPrivacy.setTrackingConsent(trackingConsent, () => {
      if (typeof window.fueled?.setConsent === 'function') {
        fueled.setConsent({
          analyticsAllowed: trackingConsent.analytics,
          preferencesAllowed: trackingConsent.preferences,
          firstPartyMarketingAllowed: trackingConsent.marketing,
          thirdPartyMarketingAllowed: trackingConsent.sale_of_data,
        });
      } else if (typeof window.fueled?.changeConsent === 'function') {
        window.fueled.changeConsent({
          analytics: trackingConsent?.analytics,
          preferences: trackingConsent?.preferences,
          marketing: trackingConsent?.marketing,
          saleOfData: trackingConsent?.sale_of_data,
        });
      }
    });
  }, [customerPrivacy]);

  useEffect(() => {
    if (status !== 'ready') {
      return;
    }

    window.OptanonWrapper = oneTrustConsentCallback;
    oneTrustConsentCallback();

    const handleConsentChanged = () => {
      oneTrustConsentCallback();
    };

    if (window.OneTrust) {
      window.OneTrust.OnConsentChanged(handleConsentChanged);
    }
  }, [oneTrustConsentCallback, status]);

  useEffect(() => {
    const handleFueledReady = () => {
      if (!customerPrivacy) return;

      const fueled = window.fueled || {};

      if (fueled?.setConsent) {
        fueled.setConsent({
          analyticsAllowed: customerPrivacy?.analytics,
          preferencesAllowed: customerPrivacy?.preferences,
          firstPartyMarketingAllowed: customerPrivacy?.marketing,
          thirdPartyMarketingAllowed: customerPrivacy?.sale_of_data,
        });
      } else if (typeof fueled?.changeConsent === 'function') {
        window.fueled.changeConsent({
          analytics: customerPrivacy?.analytics,
          preferences: customerPrivacy?.preferences,
          marketing: customerPrivacy?.marketing,
          saleOfData: customerPrivacy?.sale_of_data,
        });
      }
    };
    window.addEventListener('fueled-ready', handleFueledReady);

    return () => {
      window.removeEventListener('fueled-ready', handleFueledReady);
    };
  }, [customerPrivacy]);

  return null;
};

export function getOneTrustConsentStatus(groupId) {
  try {
    return window.OnetrustActiveGroups.includes(groupId);
  } catch (e) {
    return false;
  }
}