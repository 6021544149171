import { useEffect } from 'react';
import { useAnalytics, useScriptLoad, TRACKING_EVENTS } from './hooks';

export function FueledGtmScript({ withKey: gtmContainerId }) {
  const { register, subscribe } = useAnalytics();
  const ready = register('gmt-script');

  useScriptLoad(
    {
      id: 'gtm-pixel-script',
      innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${gtmContainerId}');`,
    },
    'head'
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    const pageViewListener = (event) => {
      (window).dataLayer.push({
        event: 'dl_route_update',
        page: event.detail.page,
        event_id: event.detail.event_id,
        event_time: event.detail.event_time,
        ecommerce: event.detail.ecommerce,
        user_properties: event.detail.user_properties,
      });
    };

    subscribe(TRACKING_EVENTS.PAGE_VIEW, pageViewListener);

    ready();
  }, []);

  return null;
}
