import { useMemo } from 'react';
import { useCountry, useSettings } from '@backpackjs/storefront';
import ProductCard from './ProductCard';
import { CollectionPromoTile } from '@/snippets/components/CollectionPromoTile';

function ProductGrid({ productList, isSearch, showSubscriptionPrice, collection }) {
  const isBrowser = typeof window !== 'undefined';
  const siteSettings = useSettings();
  const bwpSettings = siteSettings?.buyWithPrime;
  const { country } = useCountry();
  const isUs = country?.isoCode === 'US';

  const showBWPBadge = useMemo(() => {
    if (bwpSettings?.bwpLocation === 'global' && isUs) {
      return true;
    }

    if (
      bwpSettings?.bwpLocation === 'local' &&
      bwpSettings?.collectionList?.indexOf(collection?.handle) > -1 &&
      isUs
    ) {
      return true;
    }

    return false;
  }, [collection?.handle, isUs, bwpSettings?.bwpLocation, bwpSettings?.collectionList]);

  const checkScrollIntoView = (id) => {
    let enableScrollIntoView = false;
    if (isBrowser && window.location.hash.replace('#', '') === id) {
      enableScrollIntoView = true;
    }
    return enableScrollIntoView;
  };

  const buildGridItem = (item, index) => {

    if (item.handle) {
      return (
        <li key={item.legacyResourceId} className="mt-0 w-full" key={`grid-item-${index}`}>
          <ProductCard
            product={item}
            handle={item.handle}
            scrollIntoView={checkScrollIntoView(item?.legacyResourceId)}
            index={index}
            showBWPBadge={showBWPBadge}
            isSearch={isSearch}
            showSubscriptionPrice={showSubscriptionPrice}
          />
        </li>
      );
    } else {
      return (
        <li key={`cta-${index}`} className="mt-0 w-full" key={`cta-item-${index}`}>
          <CollectionPromoTile
            tile={item}
            index={index}
          />
        </li>
      );
    }
  }

  return (
    <ul
      key="product-grid"
      className="m-0 grid w-full list-none grid-cols-2 gap-y-9 gap-x-6 p-0 md:grid-cols-3 lg:grid-cols-4 mb-10"
    >
      {productList.map((product, index) => {
        return buildGridItem(product, index);
      })}
    </ul>
  );
}

export default ProductGrid;
