import Link from 'next/link.js';
import BasicInput from '../../snippets/globals/inputs/BasicInput';
import SelectElement from '../../snippets/globals/inputs/SelectElement';
import Checkbox from '../../snippets/globals/inputs/Checkbox';
import RadioButton from '../../snippets/globals/inputs/RadioButton';

export function Styleguide({ cms }) {
  const selectOptions = [
    { label: 'option one', value: 'option-one' },
    { label: 'option two', value: 'option-two' },
    { label: 'option three', value: 'option-three' },
    { label: 'option four', value: 'option-four' },
  ];

  return (
    <div className="ccontain mt-8">
      <div className="mb-8 grid lg:grid-cols-2">
        <div className="bg-white px-5 py-4">
          <h1 className="h1 mb-11">Heading One (H1)</h1>
          <h2 className="h2 mb-11">Heading Two</h2>
          <h3 className="h3 mb-11">Heading Three</h3>
          <h4 className="h4 mb-11">Heading Four</h4>
          <h5 className="h5 mb-11">Heading Five</h5>
          <h6 className="h6 mb-11">Heading Six</h6>

          <div className="grid gap-y-4">
            <p className="sub-large">Subtitle Large</p>
            <p className="sub-regular">Subtitle Regular</p>
            <p className="sub-small">Subtitle Small</p>
            <p className="sub-xsmall">Subtitle XSmall</p>
            <p className="sub-xxsmall">Subtitle XXSmall</p>
          </div>
        </div>
        <div className="bg-black px-5 py-4">
          <h1 className="mb-11 text-white">Heading One</h1>
          <h2 className="mb-11 text-white">Heading Two</h2>
          <h3 className="mb-11 text-white">Heading Three</h3>
          <h4 className="mb-11 text-white">Heading Four</h4>
          <h5 className="mb-11 text-white">Heading Five</h5>
          <h6 className="mb-11 text-white">Heading Six</h6>

          <div className="grid gap-y-4">
            <p className="sub-large text-white">Subtitle Large</p>
            <p className="sub-regular text-white">Subtitle Regular</p>
            <p className="sub-small text-white">Subtitle Small</p>
            <p className="sub-xsmall text-white">Subtitle XSmall</p>
            <p className="sub-xxsmall text-white">Subtitle XXSmall</p>
          </div>
        </div>
      </div>

      <div className="my-12">
        <p className="text-large mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <p className="text-regular mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <p className="text-small mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <p className="text-xsmall mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>

      <div className="grid grid-cols-3 gap-x-4 gap-y-10 lg:grid-cols-6">
        <div>
          <div className="h-24 bg-primary-1" />
          <p>Primary</p>
        </div>
        <div>
          <div className="h-24 bg-primary-2" />
          <p>Secondary - Pink</p>
        </div>

        <div>
          <div className="h-24 bg-secondary-1" />
          <p>Secondary - Pink 2</p>
        </div>
        <div>
          <div className="h-24 bg-secondary-2" />
          <p>Secondary - Orange</p>
        </div>

        <div>
          <div className="h-24 bg-tertiary-1" />
          <p>Secondary - Purple</p>
        </div>
        <div>
          <div className="h-24 bg-tertiary-2" />
          <p>Secondary - Cream</p>
        </div>

        <div>
          <div className="h-24 bg-quaternary-1" />
          <p>Secondary - Green</p>
        </div>
        <div>
          <div className="h-24 bg-quaternary-2" />
          <p>Secondary - Seafoam</p>
        </div>

        <div>
          <div className="h-24 bg-quinary-1" />
          <p>Secondary - Blue</p>
        </div>
        <div>
          <div className="h-24 bg-quinary-2" />
          <p>Secondary - Blue 2</p>
        </div>
        <div>
          <div className="h-24 bg-quinary-2-alt" />
          <p>Secondary - Blue 2 Alternative</p>
        </div>
        <div>
          <div className="h-24  bg-senary-3" />
          <p>Mint</p>
        </div>
        <div>
          <div className="h-24  bg-senary-4" />
          <p>Brown</p>
        </div>
        <div>
          <div className="h-24  bg-stylers-1" />
          <p>Air Dry Cream</p>
        </div>
        <div>
          <div className="h-24  bg-stylers-2" />
          <p>Sculpting Cream</p>
        </div>
        <div>
          <div className="h-24  bg-stylers-3" />
          <p>Texture Foam</p>
        </div>
        <div>
          <div className="h-24  bg-stylers-4" />
          <p>Sleek Stick</p>
        </div>
        <div>
          <div className="h-24  bg-scalp-blue" />
          <p>Scalp Blue</p>
        </div>

        <div>
          <div className="h-24 bg-grey-1" />
          <p>Grey 1</p>
        </div>
        <div>
          <div className="h-24 bg-grey-3" />
          <p>Grey 3</p>
        </div>
        <div>
          <div className="h-24 bg-grey-5" />
          <p>Grey 5</p>
        </div>
        <div>
          <div className="h-24 bg-grey-7" />
          <p>Grey 7</p>
        </div>
        <div>
          <div className="h-24 bg-grey-9" />
          <p>Grey 9</p>
        </div>
        <div>
          <div className="h-24 bg-error" />
          <p>Error</p>
        </div>
        <div>
          <div className="h-24 bg-success" />
          <p>Success</p>
        </div>
      </div>

      <div className="my-16">
        <p className="mb-2">Primary</p>
        <button type="button" className="button button--primary">
          Primary button
        </button>
      </div>
      <div className="my-16 flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col gap-4">
          <p className="mb-0">Secondary button - Outlined</p>
          <div className="relative h-[78px] w-[237px] bg-grey-4 pt-[11px] pl-[17px]">
            <button type="button" className="button button--secondary absolute">
              Outline Button
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="mb-0">Tertiary button - Outlined</p>
          <div className="relative h-[78px] w-[237px] bg-black pt-[11px] pl-[17px]">
            <button type="button" className="button button--tertiary absolute">
              Outline Button
            </button>
          </div>
        </div>
        {/* <button className="button button--secondary">
          Secondary button - Outlined
        </button>
        <button className="button button--tertiary">
          Tertiary button - Outlined
        </button> */}
      </div>
      {/* <div className="flex flex-col gap-4">
        <p>Outline Button on Dark Background</p>
        <div className="relative bg-black w-[237px] h-[78px] pt-[11px] pl-[17px]">
          <button className="absolute button button--outline-dark">
          Outline Button
          </button>
        </div>
      </div> */}
      <div className="my-16 block flex flex-col gap-4 md:flex-row">
        <button type="button" className="button button--checkout">
          checkout
        </button>
        <button type="button" className="button button--primary" disabled>
          disabled
        </button>
      </div>

      <form className="my-16 flex grid gap-x-4 gap-y-6 lg:grid-cols-3">
        <BasicInput name="firstname" label="First Name" />
        <BasicInput
          name="email"
          label="Email"
          type="email"
          required="true"
          message="Please enter a valid email address"
        />
        <div className="col-start-1">
          <SelectElement
            label="Select Option"
            name="select-option"
            options={selectOptions}
          />
        </div>

        <div className="col-start-1">
          <Checkbox label="Checkbox 1" name="checkbox-1" value="checkbox 1" />
          <Checkbox label="Checkbox 2" name="checkbox-2" value="checkbox 2" />
          <Checkbox label="Checkbox 3" name="checkbox-3" value="checkbox 3" />
          <Checkbox label="Checkbox 4" name="checkbox-4" value="checkbox 4" />
        </div>
        <div>
          <RadioButton
            label="Radio Button 1"
            name="radioes"
            value="bill"
            id="bill"
          />
          <RadioButton
            label="Radio Button 2"
            name="radioes"
            value="timmy"
            id="timmy"
          />
          <RadioButton
            label="Radio Button 3"
            name="radioes"
            value="fred"
            id="fred"
          />
        </div>
        <div>
          <p>
            This is an{' '}
            <Link href="/">
              <a>inline link</a>
            </Link>{' '}
            example
          </p>
          <p>
            <Link href="/">
              <a className="focus-link">Focus Link</a>
            </Link>
          </p>
          <div className="relative mt-2 h-[178px] w-[237px] bg-black pt-[11px] pl-[17px]">
            <div>
              <p className="text-white">
                This is an{' '}
                <Link href="/">
                  <a className="text-white">inline link</a>
                </Link>{' '}
                example
              </p>
              <p className="pt-2">
                <Link href="/">
                  <a className="focus-link-white">Focus Link</a>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="col-start-1">
          <button type="button" className="button button--primary">
            Submit
          </button>
        </div>
      </form>

      <div className="mb-4 grid lg:grid-cols-3">
        <div className="table-wrapper scrollbar col-span-2">
          <table className="">
            <thead>
              <tr>
                <td>Order Value</td>
                <td>Standard Ground</td>
                <td>3-day</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$0 - $25</td>
                <td>$4.99</td>
                <td>$6.99</td>
              </tr>
              <tr>
                <td>$0 - $25</td>
                <td>$4.99</td>
                <td>$6.99</td>
              </tr>
              <tr>
                <td>$0 - $25</td>
                <td>$4.99</td>
                <td>$6.99</td>
              </tr>
              <tr>
                <td>$0 - $25</td>
                <td>$4.99</td>
                <td>$6.99</td>
              </tr>
              <tr>
                <td>$0 - $25</td>
                <td>$4.99</td>
                <td>$6.99</td>
              </tr>
              <tr>
                <td>$10 - $125</td>
                <td>$14.99</td>
                <td>$16.99</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

Styleguide.displayName = 'Styleguide';

Styleguide.Schema = {
  category: 'Styleguide',
  label: Styleguide.displayName,
  key: 'styleguide',
  fields: [],
};
