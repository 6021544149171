import { useEffect, useState } from "react";
import { useQuizContext } from "./QuizContext"
import { ArrowLeft } from "@/snippets/icons/ArrowLeft";
import { QuizOption } from "./QuizOption";
import { Loading } from "./Loading";
import Style from "./Quiz.module.css";
import { Image } from "@/snippets/components/Image";
import { Markdown } from "@/snippets/globals/layout/Markdown";
import { Close } from "@/snippets/icons/Close";
import { useScrollLock } from "@hooks/ScrollLock";  

export function QuizQuestions () {
  const context = useQuizContext();
  const {scrollLock, scrollUnlock} = useScrollLock();
  const {quizData, quizSettings, updateQuizData} = context;
  const [messages, setMessages] = useState([]);
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [helpTextOpen, setHelpTextOpen] = useState(false);
  const isFollowUpQuestion = String(quizData.currentQuestion).indexOf('.') > -1;
  const parentQuestion = quizSettings.questions.find(question => {
    return question.index == [parseInt(quizData.currentQuestion)];
  });
  const currentQuestionArrayIndex = quizData.filteredQuestions.findIndex(question => {
    return question.index === parseInt(quizData.currentQuestion);
  });

  const getCurrentQuestionData = () => {
    if(!isFollowUpQuestion) {
      let currentQuestion = quizData.filteredQuestions.length > 0 ? quizData.filteredQuestions?.find(question => {
        return question.index == quizData.currentQuestion;
      }) : quizSettings.questions.find(question => {
        return question.index == quizData.currentQuestion;
      });

      return currentQuestion || {};
    } else {
      const followUpOption = parentQuestion.options[quizData.currentQuestion.split('.')[1]];
      return {
        isMultipleChoice: followUpOption.isMultipleChoice,
        showOptionsImages: followUpOption.showOptionsImages,
        options: followUpOption.followUpOptions,
        message1: followUpOption.followUpMessage1,
        message2: followUpOption.followUpMessage2,
        isFollowUpQuestion: true,
      }
    }
  }
  const getListMessages = () => {
    let messages = [];
    let answerMessage = null;
    if(isFollowUpQuestion) {
      answerMessage = quizData.answers.at(currentQuestionArrayIndex)?.answerMessage;
    } else {
      answerMessage =
        quizData.answers?.at(Math.max(currentQuestionArrayIndex - 1, 0))?.answerMessage?.indexOf('###') > -1
          ? quizData.answers?.at(Math.max(currentQuestionArrayIndex - 1, 0))?.answerMessage?.split('###')[1]
          : quizData.answers?.at(Math.max(currentQuestionArrayIndex - 1, 0))?.answerMessage;
    }

    if(answerMessage && answerMessage !== '') {
      messages.push(answerMessage);
    }

    if(questionData?.message1) {
      messages.push(questionData.message1);
    }
    if(questionData?.message2) {
      messages.push(questionData.message2);
    }

    return messages;
  }

  const questionData = getCurrentQuestionData();
  const [loading, setLoading] = useState(true);
  const messagesToPrint = getListMessages();
  const { isMultipleChoice, showOptionsImages } = questionData;
  const navigateToNextQuestion = () => {
    const answersData = getCurrentAnswers();

    const newAnswers = [...quizData.answers];
    let hasFollowUpQuestion = false;

    if(!isMultipleChoice) {
      hasFollowUpQuestion =
        questionData?.options[answersData]?.hasFollowUpQuestion &&
        (!!questionData?.options[answersData]?.followUpMessage1 ||
          !!questionData?.options[answersData]?.followUpMessage2) &&
        questionData?.options[answersData]?.followUpOptions?.length > 0;
    }
    newAnswers[parseInt(currentQuestionArrayIndex)] = {
      questionIndex: parseInt(quizData.currentQuestion),
      answer: questionData.isFollowUpQuestion ?
        `${quizData.answers[parseInt(currentQuestionArrayIndex)].answer}-${answersData}` :
        answersData,
      answerMessage: questionData.isFollowUpQuestion ?
        `${parentQuestion.options[quizData.answers[parseInt(currentQuestionArrayIndex)].answer].answerMessage || ''}###${questionData.options[answersData].answerMessage || ''}` :
        questionData?.options[answersData]?.answerMessage,
    }

    // Handles filter question
    if(questionData.isFilterQuestion && answersData?.length > 0) {
      const nextQuestion = quizSettings.questions.find((question) => {
        return answersData.includes(question.category);
      });
      updateQuizData({
        filter: answersData,
        currentQuestion: nextQuestion.index,
        answers: newAnswers,
        hasError: false,
      })

      return;
    }

    if(!answersData || !answersData.length) {
      updateQuizData({
        hasError: true,
      })

      return;
    };

    if (hasFollowUpQuestion || Number.parseInt(quizData.currentQuestion) !== quizData.filteredQuestions[quizData.filteredQuestions.length - 1].index) {
      setLoading(true);
      const currentQuestionArrayIndex = quizData.filteredQuestions.findIndex(question => {
        return question.index === parseInt(quizData.currentQuestion);
      });
      updateQuizData({
        currentQuestion: hasFollowUpQuestion ? `${quizData.currentQuestion}.${answersData}` : quizData.filteredQuestions[currentQuestionArrayIndex + 1].index,
        answers: newAnswers,
        hasError: false,
      });
    } else {
      if(quizSettings.mailGateSettings?.enableMailGate) {
        updateQuizData({
          quizStep: 'mailGate',
          answers: newAnswers,
          hasError: false,
        });
      } else {
        updateQuizData({
          quizStep: 'results',
          answers: newAnswers,
          hasError: false,
        })
      }
    }
  }

  const navigateToPreviousQuestion = () => {
    const currentQuestionArrayIndex = quizData.filteredQuestions.findIndex(question => {
      return question.index === parseInt(quizData.currentQuestion);
    });

    updateQuizData({
      currentQuestion: isFollowUpQuestion
        ? parseInt(quizData.currentQuestion)
        : quizData.filteredQuestions[currentQuestionArrayIndex - 1].index,
      hasError: false,
    });
  }

  const getCurrentAnswers = () => {
    const method = isMultipleChoice ? 'querySelectorAll' : 'querySelector';
    let answers = document[method]('.quiz-steps__options-container input:checked');
    let forceEverything = false;

    if(!answers || answers.length === 0) {

      return null;
    }

    if(isMultipleChoice) {
      answers = Array.from(answers);
      const fullAnswerList = Array.from(document.querySelectorAll('.quiz-steps__options-container input'))
      const answersForEverything = fullAnswerList.filter((input) => {
        return ['', 'everything'].includes(input.value.toLowerCase())
      });

      if(questionData.isFilterQuestion && answers.length === fullAnswerList.length - answersForEverything.length) {
        forceEverything = true;
      }

      if (questionData.isFilterQuestion && (answers[0].value === '' || answers[0].value === 'everything')) {
        forceEverything = true;
      }
    }
    if(isMultipleChoice && !forceEverything) {
      answers = answers.map((answerItem) => {
        return answerItem.value;
      })
    } else {
      if(questionData.isFilterQuestion && answers.value === '') {
        forceEverything = true;
      }
      answers = answers.value;
    }



    return forceEverything ? ['everything'] : answers;
  }

  const toggleInfoModal = () => {
    setHelpTextOpen(!helpTextOpen);
    if(helpTextOpen) {
      scrollUnlock(true);
    } else {
      scrollLock(true);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if(messages.length < messagesToPrint.length) {
        setMessages(messagesToPrint.slice(0, messages.length + 1));
        if(messages.length + 1 === messagesToPrint.length) {
          setLoading(false);
        }
      }
    }, 2000)

  }, [messages]);

  useEffect(() => {
    setMessages([]);
    setLoading(true);
    const currentQuestionAnswers = quizData.answers.find(answer => answer?.questionIndex == parseInt(quizData.currentQuestion));
    if(!isFollowUpQuestion) {
      setCurrentAnswers(currentQuestionAnswers);
    } else {
      setCurrentAnswers(null)
    }
  }, [quizData.currentQuestion]);

  return (
    <div className="quiz-steps__container mb-10 flex h-full w-full flex-col overflow-y-auto bg-white lg:mb-20">
      <div className="quiz-steps__load-bar h-2 bg-light-blue">
        <div
          className="quiz-steps__load-bar__progress block h-full bg-quinary-1 transition-all duration-200"
          style={{
            width: `${
              ((isFollowUpQuestion
                ? currentQuestionArrayIndex + 1.5
                : currentQuestionArrayIndex + 1) /
                quizSettings.questions.length) *
              100
            }%`,
          }}
        ></div>
      </div>
      <div className="quiz-steps__header mx-auto flex w-full max-w-2xl flex-col items-center py-3 px-5 lg:py-6">
        {quizData.currentQuestion == 0 && !!quizSettings.title && (
          <h1 className="quiz-steps__title mb-3.5 w-full text-h3-m lg:text-center lg:text-h3">
            {quizSettings.title}
          </h1>
        )}
        {quizData.currentQuestion == 0 && quizSettings.description && (
          <Markdown
            content={quizSettings.description}
            className="lg:text-center"
          />
        )}
        <div className="quiz-steps__back-button w-full">
          {quizData.currentQuestion > 0 && (
            <button
              type="button"
              className="flex items-center font-gothamBold"
              onClick={() => {
                navigateToPreviousQuestion();
              }}
            >
              <ArrowLeft className="leading-none" />
              <span className="ml-2 inline-block">Back</span>
            </button>
          )}
        </div>
      </div>
      <div className="quiz-steps__container mx-auto w-full max-w-2xl px-5">
        <div className="quiz-steps__body-content flex min-h-[540px] flex-col rounded-[20px] border border-grey-4 px-4 py-5 lg:px-12 lg:py-8">
          <div className="max-w-[80%]">
            {messages.length > 0 &&
            messages.map((message, index) => {
              return (
                <div
                  key={`message-${quizData.questionIndex}-${index}`}
                  className={`mb-5 max-w-fit rounded-[20px] rounded-bl-none px-4 pt-3 pb-4 ${quizSettings.textColor} ${quizSettings.bgColor}`}
                >
                  {message}
                </div>
              );
            })}
          </div>
          {loading && <Loading />}
          <fieldset
            aria-label="Pick your hair answer below"
            className={`quiz-steps__options-container mt-6 mb-6 mx-auto grid w-full max-w-3xl gap-x-6 gap-y-3 lg:gap-y-4
              ${questionData.optionsColumns}
            `}
          >
            <legend className="sr-only">Question options</legend>
            {!loading &&
              messages.length === messagesToPrint?.length &&
              questionData?.options?.map((option, index) => {
                return (
                  <QuizOption
                    key={option.label}
                    optionData={option}
                    isMultipleChoice={isMultipleChoice}
                    isFilter={questionData.isFilterQuestion}
                    showOptionsImages={showOptionsImages}
                    index={index}
                    currentAnswers={currentAnswers}
                  />
                );
              })}
          </fieldset>
          {!loading &&
            messages.length === messagesToPrint?.length &&
            quizData.hasError && (
            <div className="mt-3 block text-left font-gothamBold text-xs text-error">
              {isMultipleChoice
                ? 'Please select any that apply'
                : 'Please select one option'}
            </div>
          )}
          {questionData.helpTitle &&
            questionData.helpContent &&
            !loading &&
            messages.length === messagesToPrint?.length && (
              <div className="quiz-questions__help-modal mt-8 w-full text-center">
                <button
                  type="button"
                  className="focus-link mx-auto !font-primary !border-b-[1px]"
                  onClick={toggleInfoModal}
                >
                  need help?
                </button>
                {helpTextOpen && (
                  <div className="quiz-help-modal fixed top-0 left-0 z-[100001] flex h-full w-full items-center justify-center text-left">
                    <div className="quiz-help-modal__body h-auto max-h-[80%] max-w-xl overflow-y-auto bg-white px-5 py-6">
                      <div className="text-right">
                        <button
                          type="button"
                          label="Close Info Modal"
                          onClick={toggleInfoModal}
                        >
                          <Close />
                        </button>
                      </div>
                      <div className="quiz-help-modal__header">
                        <h3 className="mb-5 font-gothamBold text-lg lg:text-xl">
                          {questionData.helpTitle}
                        </h3>
                      </div>
                      <div className="quiz-help-modal__content">
                        {questionData.helpContent.map((contentBlock, index) => {
                          return (
                            <div
                              key={`content-${index}`}
                              className="mb-4 flex align-top"
                            >
                              {contentBlock.image && (
                                <div className="mr-4 w-[75px] shrink-0">
                                  <Image
                                    src={contentBlock.image.src}
                                    alt={contentBlock.image.alt}
                                    width="75"
                                    height="auto"
                                    className="block"
                                  />
                                </div>
                              )}

                              <Markdown
                                content={contentBlock.body}
                                className="mb-[1rem]"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

          <div className={`quiz-questions_footer mt-auto w-full`}>
            <button
              type="button"
              className="button button--primary mt-5 w-full"
              onClick={() => {
                navigateToNextQuestion();
              }}
            >
              {questionData.isFilterQuestion ? 'get started!' : 'continue'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
