import { useSettings } from "@backpackjs/storefront"
import { useBuilderContext } from "./BundleBuildercontext"
import { useEffect, useMemo, useState } from "react";
import { Markdown } from "@/snippets/globals/layout/Markdown";
import { useWindowDimensions } from "@/utils/Helper";
import { Close } from "@/snippets/icons/Close";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";

export default function BuilderFreeGiftToast({headerHidden}) {
  const { builderData, builderSettings } = useBuilderContext();
  const settings = useSettings();
  const { newFreeProducts: freeProductSettings } = settings ?? {};
  const currentCampaign = freeProductSettings?.gwpScenarios?.find((campaignObject) => campaignObject?.campaign === builderSettings.campaign && campaignObject.enabled == true);
  const [qualifies, setQualifies] = useState(false);
  const [wasClosed, setWasClosed] = useState(false);
  const [particlesInitialized, setParticlesInitialized] = useState(false);
  const { vpWidth } = useWindowDimensions();
  const isMobile = vpWidth < 1024;
  const getHeaderHeight = () => {
    if(typeof window !== 'undefined') {
      return document.querySelector('header').offsetHeight;
    } else {
      return 0;
    }
  }

  const headerHeight = getHeaderHeight();

  useEffect(() => {
    const bundleRequiresProduct = !!currentCampaign?.gwpProductQualify || !!currentCampaign?.gwpProductQualifySecondary;
    const bundleHasProduct = builderData.selectedProducts.some((product) => (product.handle === currentCampaign?.gwpProductQualify?.handle || product.handle === currentCampaign?.gwpProductQualifySecondary?.handle));
    
    if(bundleRequiresProduct && !bundleHasProduct) {
      setQualifies(false);
      return;
    }

    if(currentCampaign && builderData.selectedProducts.length >= currentCampaign?.gwpBundleCount) {
      setQualifies(true);
    } else {
      setQualifies(false);
    }
  }, [builderData.selectedProducts.length]);

  useEffect(() => {
    if(qualifies == false) {
      setWasClosed(false);
    }
  }, [qualifies]);

  useEffect(() => {
    if(particlesInitialized) return;

    initParticlesEngine( async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setParticlesInitialized(true);
    })
  }, [])

  const isVisible = qualifies && !wasClosed;
  const options = {
    fullScreen: {
      enable: true,
      zIndex: 10,
    },
    interactivity: {
      detectsOn: 'window',
    },
    emitters: {
      life: {
        count: 1,
        duration: 0.1,
        delay: 0.4,
      },
      position: {
        x: isMobile ? 50 : 85,
        y: isMobile ? headerHidden ? 5 : 15 : 98,
      },
      rate: {
        quantity: 200,
        delay: 0.4,
      }
    },
    particles: {
      life: {
        duration: {
          sync: true,
          value: 5,
        },
        count: 1
      },
      color: {
        value: ['#EDE757', '#14A34A', '#E61A4F', '#5B9AC5', '#00A19B'],
      },
      size: {
        value: 5,
      },
      move: {
        decay: 0.05,
        direction: isMobile ? 'none' : 'top',
        enable: true,
        gravity: {
          enable: true,
        },
        outModes: {
          top: 'none',
          default: 'destroy',
        },
        speed: {
          min: isMobile ? 5 : 15,
          max: isMobile ? 20 : 50
        },
      },
      number: {
        value: 0,
      },
      opacity: {
        value: 1,
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        animation: {
          enable: true,
          speed: 30,
        },
      },
      tilt: {
        direction: 'random',
        enable: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: 30,
        },
      },
      roll: {
        darken: {
          enable: true,
          value: 25,
        },
        enable: true,
        speed: {
          min: 5,
          max: 15,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        speed: {
          min: -7,
          max: 7,
        },
      },
      shape: {
        type: [
          'circle',
          'square',
          'polygon',
        ],
        options: {
          polygon: [
            {
              sides: 5,
            },
            {
              sides: 6,
            },
          ],
        },
      },
    },
  };

  if(!currentCampaign?.gwpQualifyMessage) return null;

  return (
    <>
    {isVisible && particlesInitialized && !wasClosed && (
      <>
        <style dangerouslySetInnerHTML={{__html: '#tsparticles canvas {z-index: 10}'}}></style>
        <Particles
          id="tsparticles"
          options={options}
        />
      </>
      )}
    <div
      className={`bundle-builder__toast-container fixed left-0 right-0 z-10 mx-auto w-max max-w-full p-3 lg:left-auto lg:top-auto lg:bottom-3 lg:right-[110px] lg:w-auto transition-all duration-150
        ${isVisible ? 'translate-y-0' : '-translate-y-[200%] lg:translate-y-full'}
      `}
      style={{
        top: isMobile ? headerHidden ? '0px' : headerHeight + 'px' : 'unset',
      }}
    >
      <div className={`toast block w-full border bg-white py-3 pl-4 pr-14 lg:w-auto max-w-sm border-grey-5`}>
        <button type="button"
        className="absolute top-7 right-7"
        onClick={() => {
          setWasClosed(true);
        }}>
          <Close />
          <div className="sr-only">Close free gift notification</div>
        </button>
        <Markdown
          content={currentCampaign?.gwpQualifyMessage}
        />
      </div>
    </div>
    </>
  );
}
