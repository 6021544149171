import { useProductByHandle } from '@backpackjs/storefront';
import { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import { useYotpoProductBottomline } from '@hooks';
import ProductPrice from '../../snippets/globals/layout/ProductPrice';
import { getMetafieldMap, getMetafieldColor } from '@/utils/Metafields';
import { getSizedImageUrl } from '@/utils/Images';
import ProductBadge from '@/snippets/products/ProductBadge';
import ProductCustomBadges from '@/snippets/products/ProductCustomBadges';
import { YotpoStars } from '@/snippets/components/YotpoStars';
import { Image } from '@/snippets/components/Image';
import BuyWithPrimeBadge from '@/snippets/products/BuyWithPrimeBadge';
import { useBuilderContext } from './BundleBuildercontext';

export default function BuilderProductCard({
  handle,
  showReviews = true,
  showBWPBadge,
  showSubscriptionPrice = false,
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product } = useProductByHandle({ handle, fetchOnMount: inView });
  const firstSellingPlan = showSubscriptionPrice
    ? product?.variants?.[0]?.sellingPlanAllocations?.[0]
    : null;

  const taggedBWP = product?.tags?.indexOf('BWP') > -1;
  const { bottomline } = useYotpoProductBottomline({
    legacyResourceId: product?.legacyResourceId,
  });
  let ShowBuyWithPrime = false;
  if (showBWPBadge && taggedBWP) {
    ShowBuyWithPrime = true;
  }

  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);

  const primaryImgSrc = useMemo(() => {
    if (!product?.images[0]?.src) return null;
    return getSizedImageUrl(product.images[0].src, '750x');
  }, [product?.images[0]?.src]);

  const primaryImgAlt = useMemo(() => {
    return product?.images[0]?.alt;
  }, [product?.images[0]?.alt]);

  const hoverImgSrc = useMemo(() => {
    if (!product?.images[1]?.src) return null;
    return getSizedImageUrl(product.images[1].src, '750x');
  }, [product?.images[1]?.src]);

  const hoverImgAlt = useMemo(() => {
    return product?.images[1]?.alt;
  }, [product?.images[1]?.alt]);

  const collection = metafieldMap.custom_collection_title;

  const context = useBuilderContext();
  const { builderData, builderSettings } = context;
  const selectedProduct = builderData.selectedProducts.some(
    (loopProduct) => loopProduct.handle === handle
  );

  const updateBundle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let { selectedProducts } = builderData;

    if (selectedProduct) {
      selectedProducts = selectedProducts.filter(
        (loopProduct) => loopProduct.handle !== handle
      );
    } else {
      selectedProducts.push(product);
    }

    context.updateBuilderData({ selectedProducts });
  };

  const setQuickViewProduct = (qvProduct) => {
    context.updateBuilderData({ quickViewProduct: qvProduct });
  };

  return (
    <div
      key={product?.legacyResourceId}
      className="product-card flex h-full flex-col pt-[45px] lg:pt-0 bg-[#f8f8f8] lg:bg-transparent"
      id={product?.legacyResourceId}
      ref={ref}
    >
      <div className="product-card__image group relative block aspect-square shrink-0">
        <ProductCustomBadges
          product={product}
        />
        <ProductBadge
          product={product}
          className={`md:!h-13 md:!w-13 !left-3 z-10 !h-11 !w-11 ${
            ShowBuyWithPrime ? '!bottom-[68px] md:!bottom-[75px]' : '!bottom-[-0.25rem] lg:!bottom-3'
          }`}
          forceSaveBadge={showSubscriptionPrice}
        />
        {ShowBuyWithPrime === true && (
          <BuyWithPrimeBadge className="z-1 md:w-13 md:h-13 absolute bottom-[-0.25rem] lg:bottom-3 left-3 h-11 w-11" />
        )}
        {primaryImgSrc && (
          <div className="l-0 t-0 absolute aspect-square h-full w-full object-cover transition-opacity duration-300 lg:group-hover:opacity-0">
            <Image
              src={primaryImgSrc}
              alt={primaryImgAlt}
              width="500"
              height="500"
              loading="lazy"
              fill
              sizes="(max-width: 768px) 50vw,
                      (max-width: 1024) 35vw,
                      27vw"
              data-primary-image
            />
          </div>
        )}
        {hoverImgSrc && (
          <div className="l-0 t-0 absolute aspect-square h-full w-full object-cover opacity-0 transition-opacity duration-300 lg:group-hover:opacity-100">
            <Image
              src={hoverImgSrc}
              alt={hoverImgAlt}
              width="500"
              height="500"
              fill
              sizes="(max-width: 768px) 50vw,
                      (max-width: 1024) 35vw,
                      27vw"
              loading="lazy"
              data-hover-image
            />
          </div>
        )}
      </div>
      <div className="product-card__body mt-3.5 pt-3 lg:pt-0 flex h-full flex-col bg-white">
        {collection && (
          <span
            className={`mb-0 font-gothamBold text-[11px] ${getMetafieldColor(metafieldMap)}`}
          >
            {collection}
          </span>
        )}
        {metafieldMap.custom_ingredient_title && (
          <span className="mb-0.5 block font-tiempos text-sm italic">
            {metafieldMap.custom_ingredient_title}
          </span>
        )}
        {(metafieldMap.custom_product_title || product?.title) && (
          <h3 className="mb-2 font-gothamBold text-sm text-grey-1">
            {metafieldMap.custom_product_title || product?.title}
          </h3>
        )}
        <ProductPrice
          variant={product?.variants[0]}
          planPrice={firstSellingPlan?.priceAdjustments[0].price?.amount}
          selectedPlan={firstSellingPlan}
          className="mb-5"
        />
        {showSubscriptionPrice === true &&
          metafieldMap.annual_savings_message !== undefined && (
            <p className="product-card__savings-message mb-5 -mt-3 block font-gothamBold text-sm font-bold text-primary-2">
              {metafieldMap.annual_savings_message}
            </p>
          )}
        <button
          type="button"
          className={`button mt-auto block w-full !min-w-0 ${
            selectedProduct
              ? 'button--secondary hover:!bg-white hover:!text-black lg:hover:!bg-black lg:hover:!text-white'
              : 'button--checkout'
          }
          ${
            !selectedProduct &&
            builderData.selectedProducts.length ===
              builderSettings.bundleSteps.length
              ? '!border-primary-2 !bg-primary-2 !text-white opacity-50'
              : ''
          }
          `}
          onClick={updateBundle}
          disabled={
            !selectedProduct &&
            builderData.selectedProducts.length ===
              builderSettings.bundleSteps.length
          }
        >
          {selectedProduct ? 'remove' : 'add to bundle'}
        </button>
        <button
          type="button"
          className="focus-link mx-auto mt-2 text-xs"
          onClick={() => {
            setQuickViewProduct(product.handle);
          }}
        >
          learn more
        </button>
      </div>
    </div>
  );
}
