import { useEffect } from "react";
import { Close } from "@/snippets/icons/Close";
import ProductCard from "@/snippets/globals/layout/ProductCard";

export function BeforeAfterProductModal( props ) {
  const { products, title, setProductModalOpen } = props;
  const compensateTop = products.some((productObject) => productObject.current === true);

  const sortedProducts = products.sort((productObject1, productObject2) => {
    const a = productObject1.current ? 1 : 0;
    const b = productObject2.current ? 1 : 0;

    return b - a;
  });

  let columnsCount = 'grid-cols-1'
  switch (products.length) {
    case 2:
      columnsCount = 'grid-cols-2'
      break;
    case 3:
      columnsCount = 'grid-cols-2 lg:grid-cols-3'
      break;
    case 4:
      columnsCount = 'grid-cols-2 lg:grid-cols-4'
      break;
    default:
      break;
  }

  const closeModal = () => {
    setProductModalOpen(false);
  }

  const outClickHandler = (event) => {
    if (!event.target.closest('.before-after__product-modal')) {
      closeModal();
    }
  }

  useEffect(() => {
    const currentScroll = window.scrollY;
    document.body.style.setProperty('--topPosition', `-${currentScroll}px`);
    document.body.classList.add('overflow-hidden', 'bg-overlay');
    setTimeout(() => {
      document.addEventListener('click', outClickHandler)
    }, 100);

    return () => {
      document.body.classList.remove('overflow-hidden', 'bg-overlay');
      const scrollTop = document.body.style.getPropertyValue('--topPosition');
      if (scrollTop) {
        window.scrollTo({
          top: parseInt(scrollTop.replace('px', '') || '0', 10) * -1,
        });
        document.body.style.removeProperty('--topPosition');
      }
      document.removeEventListener('click', outClickHandler)
    };
  }, []);

  return (
    <div
      className={`before-after__product-modal fixed bottom-0 top-1/2 left-1/2 z-[1000000] max-h-[90dvh] w-full max-w-[90%] -translate-y-1/2
        -translate-x-1/2 overflow-y-auto bg-white px-5 pb-10 h-min lg:w-max lg:max-w-[80%] lg:px-12 lg:py-12 lg:pt-0`}
    >
      <div className="before-after__modal-header sticky top-0 z-20 bg-white py-4 pr-10 text-left lg:px-0 lg:mt-2">
        {title && <h3 className="mb-0 font-gothamBold text-lg">{title}</h3>}
        <button
          className="before-after__modal-close absolute top-1 -right-4 p-4 lg:top-0"
          onClick={closeModal}
        >
          <Close />
        </button>
      </div>
      <div className={`before-after__modal-body grid gap-5 ${columnsCount} ${compensateTop == true && 'pt-[55px]' }`}>
        {sortedProducts.map((productObject, index) => (
          <div className="before-after__modal-product flex flex-col relative">
            {productObject.current == true && index == 0 && (
              <span className="before-after__modal-product-label absolute top-[-55px] inline-block self-start rounded-full bg-black py-2 px-2 lg:px-4 font-gothamBold text-xs text-white">
                current product
              </span>
            )}
            <ProductCard handle={productObject.product.handle} showQuickBuyButton quickViewButton="false" isSection />
          </div>
        ))}
      </div>
    </div>
  );
}