import { useEffect } from 'react';
import { useAnalytics, useScriptLoad, TRACKING_EVENTS } from './hooks';

const eventTypes = [TRACKING_EVENTS.ADD_TO_CART];

export function FueledBingScript({ withKey: bingPixelId }) {
  const { subscribe, register } = useAnalytics();
  const { status } = useScriptLoad(
    {
      id: 'bing-pixel-script',
      innerHTML: `
        (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${bingPixelId}", enableAutoSpaTracking: true};
        o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){
        var s=this.readyState;s &&s!=="loaded"&& s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],
        i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
      waitFor: () => !!window?.uetq,
    },
    'head'
  );

  const ready = register('bing-pixel-script');

  useEffect(() => {
    if (status !== 'ready') return;

    const eventHandler = (event) => {
      switch (event.type) {
        case 'dl_add_to_cart': {
          const [product] = event.detail.ecommerce.add.products;

          window.uetq.push('event', 'add_to_cart', {
            ecomm_prodid: product.product_id,
            ecomm_pagetype: 'product',
            ecomm_totalvalue: product.price * product.quantity,
            ...(product.quantity && { ecomm_category: product.category }),
            currency: event.detail.ecommerce.currency_code,
            items: [
              {
                id: product.product_id,
                name: product.name,
                price: product.price,
                quantity: product.quantity,
              },
            ],
          });
          break;
        }
      }
    };

    eventTypes.forEach((eventType) => subscribe(eventType, eventHandler));

    ready();
  }, [status]);

  return null;
}
