import Link from 'next/link';
import Slider from 'react-slick';
import { newLineToBr } from '../../utilities/Helper';
import styles from './FourUpCta.module.css';

function Section({ cms }) {
  const itemCount = cms?.listCta?.length || 0;
  const textColor = cms?.textColor || 'black';

  if (itemCount <= 0) {
    return null;
  }

  const slideSettings = {
    infinite: false,
    variableWidth: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipeToSlide: true,
    swipe: true,
    adaptiveHeight: false,
    useTransform: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      key={cms.cmsId}
      className={`${
        styles['four-up-cta']
      } four-up-cta lg:px-12 text-${textColor} ${
        cms?.bgColor !== 'bg-white' && cms?.bgColor !== 'transparent'
          ? ` pt-9 pb-9 md:pt-16 md:pb-10 ${cms?.bgColor}`
          : ``
      }`}
      data-comp={Section.displayName}
      style={{ backgroundColor: cms?.customBgColor }}
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="mx-auto max-w-[1120px]">
        {cms?.section_title && (
          <h2 className="mt-0 mb-8 px-4 text-center text-h2-m md:px-12 md:text-h3 lg:mb-11 lg:px-0">
            {cms?.section_title}
          </h2>
        )}
        {cms?.listCta?.length > 0 && (
          <div className="-ml-4 overflow-hidden px-4 sm:-ml-6 md:px-12 lg:px-0">
            <CustomSlider
              settings={slideSettings}
              className="flex flex-nowrap md:flex-wrap md:justify-center"
            >
              {cms?.listCta?.map((cta, index) => {
                return (
                  <CtaHtml
                    key={index}
                    item={cta?.cta}
                    index={index}
                    textColor={textColor}
                  />
                );
              })}
            </CustomSlider>
          </div>
        )}
      </div>
    </div>
  );
}

const buildCtaContent = (item) => {
  let content = '';
  if (item?.pre_description) {
    content += `<strong>${item.pre_description}</strong>`;
  }
  if (item?.description) {
    content += item.description;
  }
  return content;
};

function CustomSlider({ className, settings, children }, props) {
  return (
    <>
      <div className="md:hidden">
        <Slider {...settings} {...props}>
          {children}
        </Slider>
      </div>
      <div className={`max-md:hidden ${className}`}>{children}</div>
    </>
  );
}

function CtaHtml({ item, index, textColor }) {
  const colorClass = `!text-white text-black`;
  if (!item?.image?.src && !item?.title && !item?.description) return null;

  return (
    <div
      className="w-[283px] shrink-0 snap-center pl-4 text-sm sm:w-full sm:pl-6 md:w-1/2 md:pb-6 md:text-base"
      data-slide
      data-index={index}
    >
      {item?.link?.text && item?.link?.url ? (
        <Link href={item?.link?.url}>
          <a
            className={`${styles.ctaContainer} text-${textColor} block border no-underline border-${textColor} h-full text-${textColor}`}
            href={item?.link?.url}
          >
            {item?.image?.src && (
              <div
                className={`w-full border-b border-${textColor}`}
                style={{
                  aspectRatio: item.aspect_ratio,
                }}
              >
                <img
                 src={item.image.src}
                 className="w-full"
                 alt={item.image_alt}
                 loading="lazy"
                 width="1092"
                 height="752"
                />
              </div>
            )}
            <div className="px-7 py-8 md:px-9 md:py-11">
              {item?.title && (
                <p className="mt-0 mb-4 block font-tiempos text-h3-m md:text-h4">
                  {item?.title}
                </p>
              )}
              {(item?.pre_description || item?.description) && (
                <div
                  className="block"
                  dangerouslySetInnerHTML={{
                    __html: newLineToBr(buildCtaContent(item)),
                  }}
                />
              )}
              {item?.link?.url && item?.link?.text && (
                <span
                  href={item?.link?.url}
                  className={`${styles.readmoreLink} focus-link mt-3.5 inline-block font-gothamBold text-sm md:mt-4 !text-${textColor} border-${textColor}`}
                >
                  {item?.link?.text}
                </span>
              )}
            </div>
          </a>
        </Link>
      ) : (
        <div
          className={`${styles.ctaContainer} text-${textColor} border border-${textColor} h-full`}
        >
          {item?.image?.src && (
            <div
              className={`relative w-full border-b border-${textColor}`}
              style={{
                aspectRatio: item.aspect_ratio,
              }}
            >
              <img
                src={item.image.src}
                className="w-full"
                alt={item.image_alt}
                loading="lazy"
                 width="1092"
                 height="752"
              />
            </div>
          )}
          <div className="px-7 py-8 md:px-9 md:py-11">
            {item?.title && (
              <p className="mt-0 mb-4 block font-tiempos text-[22px] md:text-h4">
                {item?.title}
              </p>
            )}
            {item?.description && (
              <div className="block">
                {item?.pre_description && (
                  <strong>{item?.pre_description}</strong>
                )}
                {item?.description}
              </div>
            )}
            {item?.link?.url && item?.link?.text && (
              <Link href={item?.link?.url}>
                <a
                  href={item?.link?.url}
                  className={`${styles.readmoreLink} focus-link mt-3.5 inline-block font-gothamBold text-sm md:mt-4 !text-${textColor} border-${textColor}`}
                >
                  {item?.link?.text}
                </a>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Section.displayName = '4 Up CTA';
CustomSlider.displayName = 'CustomSlider';
CtaHtml.displayName = 'CtaHtml';

Section.Schema = {
  category: 'Extras',
  label: Section.displayName,
  key: 'four-up-cta',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'group-list',
      name: 'listCta',
      label: 'CTA',
      description: 'List CTAs',
      fields: [
        {
          label: 'CTA',
          name: 'cta',
          component: 'group',
          fields: [
            {
              label: 'CTA Image',
              name: 'image',
              component: 'image',
              description: 'Recommended Image Size: 1092px x 752px',
            },
            {
              name: 'image_alt',
              label: 'Image alt text',
              component: 'text',
            },
            {
              name: 'aspect_ratio',
              label: 'CTA Aspect Ratio',
              description: 'Width divided by height, e.g. 16:9 image is "1.78"',
              component: 'number',
            },
            {
              label: 'CTA Title',
              name: 'title',
              component: 'text',
            },
            {
              name: 'pre_description',
              label: 'CTA Pre-description',
              component: 'text',
            },
            {
              name: 'description',
              label: 'CTA Description',
              component: 'textarea',
              description:
                'Recommended to keep the description under 180 characters, or have consistency across all ingredients description',
            },
            {
              label: 'CTA URL',
              name: 'link',
              component: 'link',
            },
          ],
        },
      ],
    },
    {
      name: 'section_title',
      label: 'Section Title',
      component: 'text',
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color',
      options: [
        { label: 'Light', value: 'white' },
        { label: 'Dark', value: 'black' },
      ],
      defaultValue: 'black',
    },
    {
      component: 'select',
      name: 'bgColor',
      label: 'Background Color',
      description: 'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' },
        { label: 'Scalp Blue', value: 'bg-scalp-blue' },
      ],
      defaultValue: 'bg-white',
    },
    {
      component: 'color',
      name: 'customBgColor',
      label: 'Custom Background Color',
      description: 'Custom Background color',
    },
  ],
};

export const FourUpCta = Section;
