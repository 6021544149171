import {
  useSettings,
  useStorefrontInit,
  useCartAddDiscountUrl,
} from '@backpackjs/storefront';

function RootStyles() {
  const settings = useSettings();

  const getButtonColor = (buttonKey) => {
    if (settings?.themeColors && settings.themeColors[buttonKey]) {
      const colorKey = settings.themeColors[buttonKey];
      return settings.themeColors[colorKey];
    }
  };

  return (
    <style>{`
      :root {
        --primary-1: ${settings?.themeColors?.primaryColor || '#444444'};
        --primary-2: ${settings?.themeColors?.primaryColorAlt || '#E61A4F'};
        --secondary-1: ${settings?.themeColors?.secondaryColor || '#EB80A8'};
        --secondary-2: ${settings?.themeColors?.secondaryColorAlt || '#FF4713'};
        --tertiary-1: ${settings?.themeColors?.tertiaryColor || '#552E90'};
        --tertiary-2: ${settings?.themeColors?.tertiaryColorAlt || '#CEB4A8'};
        --quaternary-1: ${settings?.themeColors?.quaternaryColor || '#14A34A'};
        --quaternary-2: ${
          settings?.themeColors?.quaternaryColorAlt || '#00A19B'
        };
        --quinary-1: ${settings?.themeColors?.quinaryColor || '#5B9AC5'};
        --quinary-2: ${settings?.themeColors?.quinaryColorAlt || '#B8C5D6'};
        --quinary-2-alt: ${
          settings?.themeColors?.quinaryColorTwoAlt || '#5B7E96'
        };
        --senary-1: ${settings?.themeColors?.senaryColor || '#d0021b'};
        --senary-2: ${settings?.themeColors?.senaryColorAlt || '#218246'};
        --senary-3: ${settings?.themeColors?.senaryColorThree || '#9DD4CA'};
        --senary-4: ${settings?.themeColors?.senaryColorFour || '#936953'};
        --senary-5: ${settings?.themeColors?.senaryColorFive || '#57C1A5'};
        --stylers-1: ${settings?.themeColors?.stylersColorOne || '#00a7b5'};
        --stylers-2: ${settings?.themeColors?.stylersColorTwo || '#8094dd'};
        --stylers-3: ${settings?.themeColors?.stylersColorThree || '#ff585d'};
        --stylers-4: ${settings?.themeColors?.stylersColorFour || '#d74388'};
        --grey-1: ${settings?.themeColors?.greyOne || '#444444'};
        --grey-3: ${settings?.themeColors?.greyThree || '#757575'};
        --grey-4: ${settings?.themeColors?.greyFour || '#DFDFDF'};
        --grey-5: ${settings?.themeColors?.greyFive || '#979797'};
        --grey-7: ${settings?.themeColors?.greySeven || '#DFDFDF'};
        --grey-9: ${settings?.themeColors?.greyNine || '#F5F5F5'};
        --white: ${settings?.themeColors?.white || '#FFFFFF'};
        --black: ${settings?.themeColors?.black || '#444444'};
        --yellow: ${settings?.themeColors?.yellow || '#EDE757'};
        --light-blue: ${settings?.themeColors?.lightBlue || '#EDF0F5'};
        --scalp-blue: ${settings?.themeColors?.scalpBlue || '#597D95'};

        --button-primary-bg: ${getButtonColor('buttonPrimaryBG') || '#444444'};
        --button-primary-bg-hover: ${
          getButtonColor('buttonPrimaryBGHover') || '#757575'
        };
        --button-secondary-bg: ${
          getButtonColor('buttonSecondaryBG') || 'transparent'
        };
        --button-secondary-bg-hover: ${
          getButtonColor('buttonSecondaryBGHover') || '#444444'
        };
        --button-outline-bg: ${getButtonColor('buttonOutlineBG') || '#ffffff'};
        --button-outline-bg-hover: ${
          getButtonColor('buttonOutlineBGHover') || '#444444'
        };
        --button-outline-dark-bg: ${
          getButtonColor('buttonOutlineDarkBG') || '#444444'
        };
        --button-outline-dark-bg-hover: ${
          getButtonColor('buttonOutlineDarkBGHover') || '#ffffff'
        };
        --button-atc: ${getButtonColor('buttonATCBG') || '#444444'};
        --button-atc-hover: ${getButtonColor('buttonATCHoverBG') || '#757575'};
        --button-checkout: ${getButtonColor('buttonCheckoutBG') || '#E61A4F'};
        --button-checkout-hover: ${
          getButtonColor('buttonCheckoutHoverBG') || '#EB80A8'
        };

        --error-state: #d0021b;
        --success-state: #218246;
        --ada-outline: #4D90FE;
      }
    `}</style>
  );
}

export default RootStyles;
